import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: #f7f7f7;

	.mo-block-wrap {
		display: none;
	}
	.mo-comment-box {
		display: none;
	}
	.mo-comment-bg-box {
		display: none;
	}
	.mo-mentor-wrap {
		display: none;
	}

	.my-wrapper {
		max-width: 1248px;
		width: 100%;
		margin: 0 auto;
		padding: 190px 0px;
		box-sizing: border-box;
		.top-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.my-title {
				font-weight: 700;
				font-size: 48px;
				line-height: 60px;
			}
			button {
				padding: 21px 27px;
				background-color: #000;
				color: #fff;
				border-radius: 18px;
				border: none;
				font-weight: 700;
				font-size: 19px;
				line-height: 24px;
				display: flex;
				align-items: center;
				gap: 13px;
				height: 66px;
			}
		}
		.profile-wrap {
			margin-top: 40px;
			display: flex;
			gap: 32px;
			.left-box {
				max-width: 888px;
				width: 100%;
				background: #ffffff;
				border-radius: 28px;
				padding: 50px;
				position: relative;
				.profile-box {
					.pro-wrap {
						display: flex;
						gap: 29px;
						img {
							width: 80px;
						}
						.profile-text-box {
							.profile-department {
								font-weight: 300;
								font-size: 22px;
								line-height: 43px;
								letter-spacing: -0.02em;
								color: #686868;
							}
							.profile-hello {
								font-weight: 300;
								font-size: 38px;
								line-height: 43px;
								letter-spacing: -0.02em;
								span {
									font-weight: 700;
								}
							}
						}
					}
					.my-info-box {
						margin-top: 50px;
						.email {
							display: flex;
							font-weight: 300;
							font-size: 18px;
							line-height: 22px;
						}
						.phone {
							display: flex;
							margin-top: 20px;
						}
						.list-title {
							color: #868686;
						}
						.list-text {
							text-indent: 30px;
						}
						.mail-text {
							padding-left: 5px;
						}
					}
					.btn-wrap {
						display: flex;
						flex-direction: column;
						gap: 11px;
						position: absolute;
						right: 50px;
						bottom: 50px;
						button {
							padding: 20px 43px;
							font-weight: 700;
							font-size: 17.605px;
							line-height: 22px;
							letter-spacing: -0.03em;
							border: 1.5px solid #e9e9e9;
							border-radius: 90px;
							background-color: #fff;
							display: flex;
							align-items: center;
							gap: 8px;
							height: 62px;
						}
						.black-btn {
							background-color: #000;
							color: #fff;
							border: 1.5px solid #000;
						}
					}
				}
			}
			.right-box {
				background-color: #f9dd4a;
				border-radius: 28px;
				max-width: 330px;
				width: 100%;
				transition: all 0.3s;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
				.right-text-box {
					padding: 40px;
					letter-spacing: -0.03em;
					line-height: 35px;
					.right-title {
						font-weight: 700;
						font-size: 28px;
					}
					.right-text {
						font-weight: 300;
						font-size: 18px;
						margin-top: 12px;
					}
					.icon-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: relative;
						top: 27px;
						.icon-under-arrow {
							width: 52px;
							height: 52px;
							border-radius: 50%;
							background-color: #fff;
							position: relative;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}
				}
			}
		}
		.under-box {
			margin-top: 30px;
			display: flex;
			gap: 32px;
			text-align: center;
			.point-list {
				display: flex;
				gap: 120px;
				padding: 27px 51px 24px 51px;
				max-width: 888px;
				width: 100%;
				height: 96px;
				background-color: #fff;
				border-radius: 28px;
				li {
					position: relative;
					&:after {
						position: absolute;
						content: '';
						top: 0;
						left: 120px;
						width: 1px;
						height: 45px;
						background-color: #eee;
					}
					&:last-child::after {
						display: none;
					}
					.point-title {
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						letter-spacing: -0.02em;
						color: #666666;
					}
					.point-text {
						font-weight: 700;
						font-size: 16px;
						line-height: 20px;
						margin-top: 8px;
					}
				}
			}
			.faq-box {
				background: #736c61;
				border-radius: 28px;
				padding: 40px;
				max-width: 330px;
				width: 100%;
				height: 96px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}

				.fap-icon-box {
					display: flex;
					align-items: center;
					gap: 13px;
					.faq-title {
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: #fff;
						position: relative;
					}
				}
			}
		}
		.mentor-wrap {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			height: 403px;
			.mentee-left {
				max-width: 888px;
				width: 100%;
				background-color: #fff;
				border-radius: 27px;
				padding: 40px 50px;
				.mentor-top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.mentor-left {
						display: flex;
						align-items: center;
						gap: 11px;
						padding: 11px 11px 0 11px;
						.mentor-top-title {
							font-weight: 700;
							font-size: 24px;
							line-height: 30px;
							color: #111;
						}
						.mentor-border {
							font-weight: 500;
							font-size: 12px;
							line-height: 15px;
							letter-spacing: 0.02em;
							color: #ffac00;
							padding: 5px 12px;
							border: 1px solid #ffac00;
							border-radius: 100px;
						}
					}
					.mentor-right {
						display: flex;
						align-items: center;
						button {
							background-color: #fff;
							border: none;
							font-weight: 700;
							font-size: 16px;
							line-height: 20px;
							letter-spacing: -0.02em;
							.kbds-arrow {
								svg {
									position: relative;
									right: 11px;
									top: 3px;
								}
							}
						}
					}
				}
				.current-wrap {
					.current-list {
						display: flex;
						justify-content: space-between;
						padding: 110px 5px 64px 5px;
						text-align: center;
						li {
							letter-spacing: -0.02em;
							font-size: 12px;
							line-height: 32px;
							position: relative;
							.icon-current {
								svg {
									width: 43px;
									position: relative;
									z-index: 1;
								}
							}
							.round-text {
								font-weight: 500;
								color: #bebebe;
								margin-top: 10px;
							}

							.round-box {
								background: rgba(213, 213, 213, 0.12);
								border-radius: 20px;
								width: 71px;
								height: 25px;

								.schedule-text {
									font-weight: 400;
									color: #9f9f9f;
									position: relative;
									top: -4px;
								}
								.complete-text {
									color: #e4c000;
								}
							}
							.complete-box {
								background: rgba(249, 221, 74, 0.12);
							}
							&::after {
								position: absolute;
								content: '';
								top: 22px;
								left: 44px;
								width: 149px;
								height: 1px;
								border-top: 2px dashed #bebebe;
							}
							&:last-child::after {
								display: none;
							}
						}
						.on {
							.icon-current {
								svg {
									path {
										fill: #f9dd4a;
									}
								}
							}
							&::after {
								position: absolute;
								content: '';
								top: 22px;
								left: 44px;
								width: 149px;
								height: 1px;
								border-top: 2px solid #f9dd4a;
							}
						}
					}
				}
			}
			.mentee-right {
				max-width: 330px;
				width: 100%;
				border-radius: 27px;
				background-color: #fff;
				padding: 40px;
				letter-spacing: -0.03em;
				color: #0e0e0e;
				position: relative;
				.mentee-title {
					font-weight: 700;
					font-size: 28px;
					line-height: 35px;
				}
				.mentee-text {
					font-weight: 300;
					font-size: 18px;
					line-height: 35px;
					margin-top: 12px;
				}

				.manual-icon-box {
					display: flex;
					align-items: end;
					justify-content: space-between;
					padding-top: 140px;
					.manual-arrow-box {
						width: 52px;
						height: 52px;
						background-color: #f7f7f7;
						border-radius: 50%;
						position: relative;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}
		.post-title-wrap {
			margin-top: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.post-title-box {
				display: flex;
				justify-content: space-between;
				align-items: center;
				max-width: 823px;
				width: 100%;
				button {
					font-weight: 700;
					font-size: 17px;
					line-height: 22px;
					letter-spacing: -0.03em;
					background-color: transparent;
					border: none;
					.icon-add-pen {
						margin-right: 4px;
					}
				}
			}
			.post-title {
				font-weight: 700;
				font-size: 28px;
				line-height: 35px;
			}
		}
		.post-wrap {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			.post-box {
				max-width: 823px;
				width: 100%;
				.writing-wrap {
					background-color: #fff;
					border-radius: 27px;
					padding: 30px 0 20px 30px;
					.post-line {
						border-top: 1px solid #eeeeee;
						margin-right: 30px;
						margin-top: 18px;
					}
					button {
						font-weight: 300;
						font-size: 14px;
						line-height: 17px;
						margin-top: 23px;
						position: relative;
						left: 50%;
						transform: translateX(-50%);
						background-color: #fff;
						border: none;
					}
				}
			}
			.comment-wrap {
				background: #ffffff;
				border-radius: 27px;
				max-width: 395px;
				width: 100%;
				height: 859px;
				padding: 30px;
				position: relative;
				/* overflow-y: auto; */
				&::-webkit-scrollbar {
					display: none;
				}
				.comment-list {
					.comment-box {
						background: rgba(79, 79, 79, 0.88);
						border-radius: 27px 27px 27px 8px;
						padding: 26px 22px 17px 24px;
						color: #fff;
						.comment {
							font-weight: 300;
							font-size: 15px;
							line-height: 19px;
							margin-bottom: 18px;
						}
						.date {
							font-weight: 300;
							font-size: 12px;
							line-height: 15px;
							text-align: right;
							.icon-time {
								svg {
									position: relative;
									top: 2.5px;
									right: 6px;
								}
							}
						}
					}
					.comment-user {
						margin-top: 15px;
						margin-bottom: 33px;
						display: flex;
						align-items: center;
						gap: 6px;
						&:last-child {
							margin-bottom: 0;
						}
						img {
							width: 27px;
						}
						.opinion {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							font-weight: 700;
							font-size: 14px;
							line-height: 18px;
							letter-spacing: -0.03em;
						}
					}
				}
				.comment-bg-box {
					position: relative;
					.comment-bg {
						width: 100%;
						height: 75px;
						position: absolute;
						bottom: 0px;
						right: 0;
						background: linear-gradient(
							180deg,
							rgba(255, 255, 255, 0) 0%,
							#ffffff 100%
						);

						button {
							font-weight: 700;
							font-size: 14px;
							line-height: 17px;
							padding: 12px 27px;
							background-color: #fff;
							border-radius: 40px;
							border: none;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
			.interest-wrap {
				margin-top: 40px;
				.interest-title {
					font-weight: 700;
					font-size: 28px;
					line-height: 35px;
				}
				.interest-bg {
					background-color: #fff;
					border-radius: 27px;
					padding: 30px 30px 20px 30px;
					margin-top: 20px;
					height: 379px;
					.none-box {
						text-align: center;
						padding: 90px 0;
						.none-text {
							font-weight: 300;
							font-size: 16px;
							line-height: 20px;
							margin-top: 20px;
							color: #888888;
						}
					}
				}
				.post-line {
					border-top: 1px solid #eeeeee;
					margin-top: 18px;
				}
				button {
					font-weight: 300;
					font-size: 14px;
					line-height: 17px;
					margin-top: 23px;
					position: relative;
					left: 50%;
					transform: translateX(-50%);
					background-color: #fff;
					border: none;
				}
			}
		}
		.club-wrap {
			margin-top: 30px;
			display: flex;
			gap: 30px;
			.club-box {
				width: calc((100% - 60px) / 3);
				background-color: #fff;
				border-radius: 27px;
				padding: 36px 42px 0 42px;
				.club-title {
					font-weight: 700;
					font-size: 24px;
					line-height: 30px;
				}
				.club-list {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					img {
						width: 75px;
					}
					.club-name {
						margin-top: 15px;
						font-weight: 500;
						font-size: 13px;
						line-height: 16px;
						color: #777777;
					}
				}
				.allmore-line {
					border-top: 1px solid #eeeeee;
					margin-top: 20px;
				}
				button {
					width: 100%;
					font-weight: 300;
					font-size: 14px;
					line-height: 17px;
					border: none;
					background-color: transparent;
					margin: 22px 0;
				}
				.no-open {
					padding: 25px 0 32px 0;
					text-align: center;
					.none-text {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						color: #888888;
						margin-top: 20px;
					}
				}
			}
		}
		.none-club-wrap {
			position: relative;
			.none-club-box {
				max-width: 396px;
				width: 100%;
				height: 292px;
				border-radius: 27px;
				background-color: #fff;
				opacity: 0.8;
				position: absolute;
				top: -292px;
				.no-open {
					padding: 90px 0;
					text-align: center;
					.none-text {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						margin-top: 20px;
						color: #888888;
					}
				}
			}
		}
	}

	@media ${(props) => props.theme.mobile} {
		.mo-block-wrap {
			display: block;
		}
		.my-wrapper {
			padding: 50px 30px;
			.top-wrap {
				align-items: end;
				.my-title {
					font-size: 22px;
					line-height: 160%;
				}
				button {
					padding: 6px 17px;
					border-radius: 18px;
					font-size: 12px;
					line-height: 15px;
					gap: 8px;
					height: 38px;
					.icon-home {
						svg {
							width: 14px;
							position: relative;
							top: 1px;
						}
					}
				}
			}
			.mo-none-box {
				display: none;
			}
			.mo-pro-box {
				margin-top: 24px;
				background: #ffffff;
				border-radius: 10px;
				padding: 20px;
				.pro-wrap {
					display: flex;
					justify-content: space-between;
					.profile-department {
						font-weight: 300;
						font-size: 12px;
						line-height: 21px;
						letter-spacing: -0.02em;
						color: #686868;
					}
					.profile-hello {
						font-weight: 300;
						font-size: 20px;
						line-height: 21px;
						letter-spacing: -0.02em;
						margin-top: 6px;
						span {
							font-weight: 700;
						}
					}
					.crtstal-btn {
						button {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: #fff;
							border: 1px solid #e9e9e9;
							position: relative;
							.icon-crtstal {
								svg {
									width: 16px;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
							}
						}
					}
				}
				.my-info-box {
					margin-top: 30px;
					.email {
						display: flex;
					}
					.phone {
						display: flex;
						margin-top: 6px;
					}
				}
				.list-title {
					font-weight: 300;
					font-size: 12px;
					line-height: 15px;
					color: #868686;
				}
				.list-text {
					font-weight: 300;
					font-size: 12px;
					line-height: 15px;
					text-indent: 11px;
				}
				.mail-text {
					padding-left: 8px;
				}
			}
			.mo-under-box {
				margin-top: 12px;
				.under-wrap {
					width: 100%;
					display: flex;
					gap: 10px;
					.point-list {
						background-color: #fff;
						border-radius: 10px;
						padding: 20px 21px;
						width: 36%;
						height: 250px;
						li {
							text-align: center;
							margin-top: 19px;
							&:first-child {
								margin-top: 0;
							}
							.point-title {
								font-weight: 300;
								font-size: 11px;
								line-height: 14px;
								letter-spacing: -0.02em;
								color: #666666;
							}
							.point-text {
								margin-top: 5px;
								font-weight: 700;
								font-size: 12px;
								line-height: 15px;
							}
							.acc-title {
								margin-top: 2px;
								font-weight: 300;
								font-size: 10px;
								line-height: 12px;
								color: #9f9f9f;
							}
						}
					}
					.right-box {
						width: 64%;
						&:hover {
							opacity: 1;
						}
						.right-text-box {
							background: #f9dd4a;
							border-radius: 10px;
							padding: 20px;
							letter-spacing: -0.03em;
							position: relative;
							height: 180px;
							.right-title {
								font-weight: 700;
								font-size: 18px;
								line-height: 21px;
							}
							.right-text {
								margin-top: 8px;
								font-weight: 300;
								font-size: 11px;
								line-height: 160%;
							}
						}
						.add-box {
							position: absolute;
							right: 15px;
							button {
								font-weight: 700;
								font-size: 12px;
								line-height: 15px;
								letter-spacing: -0.03em;
								border: none;
								background-color: transparent;
								.icon-add-pen {
									svg {
										width: 14px;
										position: relative;
										top: 3px;
										right: 6px;
									}
								}
							}
						}
						.icon-box {
							display: flex;
							justify-content: space-between;
							align-items: end;
							margin-top: 50px;
							.icon-under-arrow {
								width: 32px;
								height: 32px;
								background-color: #fff;
								border-radius: 50%;
								position: relative;
								svg {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
							}
							.icon-sauare {
								svg {
									width: 32px;
									height: 32px;
								}
							}
						}
						.faq-box {
							background: #736c61;
							border-radius: 10px;
							margin-top: 10px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 20px;
							height: 60px;
							.menubook-icon {
								svg {
									width: 27px;
									position: relative;
									top: 1px;
								}
							}
							.big-arrow {
								svg {
									width: 23px;
								}
							}
							.fap-icon-box {
								display: flex;
								align-items: center;
								.faq-title {
									font-weight: 700;
									font-size: 18px;
									line-height: 22px;
									color: #fff;
									margin-left: 10px;
								}
							}
						}
					}
				}
			}

			/*  */
			.mentor-wrap {
				display: none;
			}
			.mo-mentor-wrap {
				display: block;
				.mentee-top {
					margin-top: 10px;
					background-color: #fff;
					border-radius: 10px;
					padding: 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.manual-box {
						display: flex;
						align-items: center;
						.mentee-title {
							font-weight: 700;
							font-size: 18px;
							line-height: 22px;
							margin-left: 10px;
						}
					}
					.big-arrow {
						svg {
							width: 23px;
							path {
								stroke: #000;
							}
						}
					}
				}
				.mentee-under {
					margin-top: 10px;
					background-color: #fff;
					border-radius: 10px;
					padding: 0 20px;
					.mentor-top {
						.mentor-left {
							padding-top: 30px;
							display: flex;
							align-items: center;
							.mentor-top-title {
								font-weight: 700;
								font-size: 20px;
								line-height: 25px;
								color: #111;
								margin-right: 13px;
							}
							.mentor-border {
								font-weight: 500;
								font-size: 12.3px;
								line-height: 15px;
								letter-spacing: 0.02em;
								color: #ffac00;
								border: 1px solid #ffac00;
								padding: 3px 9px;
								border-radius: 81px;
							}
						}
						.mentor-right {
							margin-top: 20px;
							display: flex;
							justify-content: end;
							align-items: center;
							button {
								font-weight: 700;
								font-size: 13px;
								line-height: 16px;
								letter-spacing: -0.02em;
								border: none;
								background-color: transparent;
								.kbds-arrow {
									margin-right: 6px;
									svg {
										position: relative;
										top: 2px;
									}
								}
							}
						}
					}
					.current-wrap {
						margin-top: 10px;
						white-space: nowrap;
						overflow-x: auto;
						.current-list {
							display: flex;
							gap: 30px;
							justify-content: space-between;
							text-align: center;
							li {
								letter-spacing: -0.02em;
								font-size: 12px;
								line-height: 32px;
								position: relative;
								.icon-current {
									svg {
										width: 46px;
										position: relative;
										z-index: 1;
									}
								}
								.round-text {
									font-weight: 500;
									color: #bebebe;
									margin-top: 8px;
								}
								.date {
									font-weight: 500;
									font-size: 13px;
									span {
										color: #888;
									}
								}
								.round-box {
									.schedule-text {
										font-weight: 400;
										color: #9f9f9f;
										position: relative;
										top: -4px;
									}
									.complete-text {
										color: #e4c000;
									}
								}
								.complete-box {
									background: rgba(249, 221, 74, 0.12);
									border-radius: 20px;
									width: 71px;
									height: 25px;
									margin: 0 auto;
								}
								&::after {
									position: absolute;
									content: '';
									top: 22px;
									left: 45px;
									width: 75px;
									height: 1px;
									border-top: 2px dashed #bebebe;
								}
								&:last-child::after {
									display: none;
								}
							}
							.on {
								.icon-current {
									svg {
										path {
											fill: #f9dd4a;
										}
									}
								}
								&::after {
									position: absolute;
									content: '';
									top: 22px;
									left: 60px;
									width: 75px;
									height: 1px;
									border-top: 2px solid #f9dd4a;
								}
							}
						}
					}
					.more-box {
						border-top: 1px solid #eee;
						margin-top: 15px;
						button {
							width: 100%;
							padding: 13px 0;
							font-weight: 300;
							font-size: 14px;
							line-height: 17px;
							background-color: #fff;
							border: none;
						}
					}
					.gradient-box {
						position: relative;
						.bg-l {
							position: absolute;
							z-index: 1;
							top: -220px;
							width: 102px;
							height: 167px;
							background: linear-gradient(
								270deg,
								#ffffff 0%,
								rgba(255, 255, 255, 0) 66.67%
							);
							transform: matrix(-1, 0, 0, 1, 0, 0);
						}
						.bg-r {
							position: absolute;
							z-index: 1;
							top: -220px;
							right: 0;
							width: 102px;
							height: 167px;
							background: linear-gradient(
								270deg,
								#ffffff 0%,
								rgba(255, 255, 255, 0) 66.67%
							);
						}
					}
				}
			}
			.post-title-wrap {
				margin-top: 10px;
				.post-title-box {
					button {
						font-size: 12px;
						line-height: 15px;
						.icon-add-pen {
							margin-right: 2px;
							svg {
								width: 13px;
								position: relative;
								top: 2px;
							}
						}
					}
				}
				.post-title {
					font-size: 20px;
					line-height: 160%;
				}
				.mo-post-title {
					display: none;
				}
			}
			.post-wrap {
				flex-wrap: wrap;
				margin-top: 10px;
				.post-box {
					max-width: 823px;
					width: 100%;
					.writing-wrap {
						background-color: #fff;
						border-radius: 10px;
						padding: 30px 0 20px 30px;
						.post-line {
							border-top: 1px solid #eeeeee;
							margin-right: 30px;
							margin-top: 18px;
						}
						button {
							font-weight: 300;
							font-size: 14px;
							line-height: 17px;
							margin-top: 23px;
							position: relative;
							left: 50%;
							transform: translateX(-50%);
							background-color: #fff;
							border: none;
						}
					}
				}
				.mo-comment-box {
					display: block;
					margin-top: 20px;
					margin-bottom: 10px;
					.mo-comment-title {
						font-weight: 700;
						font-size: 20px;
						line-height: 160%;
					}
				}
				.comment-wrap {
					border-radius: 10px;
					height: unset;
					padding: 20px 20px 14px 20px;
					.comment-list {
						.comment-box {
							border-radius: 20px 20px 20px 4px;
							padding: 20px;
							.comment {
								font-size: 14px;
								line-height: 17px;
								margin-bottom: 28px;
							}
						}
						.comment-user {
							margin-top: 10px;
							margin-bottom: 16px;
							.opinion {
								font-size: 12px;
								line-height: 15px;
							}
						}
					}
					.comment-bg-box {
						display: none;
					}
					.mo-comment-bg-box {
						display: block;
						border-top: 1px solid #eeeeee;
						margin-top: 17px;
						button {
							margin-top: 13px;
							font-weight: 300;
							font-size: 14px;
							line-height: 17px;
							color: #000000;
							background-color: #fff;
							border: none;
							position: relative;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
				.interest-wrap {
					margin-top: 20px;
					.interest-title {
						font-size: 20px;
						line-height: 160%;
					}
					.interest-bg {
						border-radius: 10px;
						padding: 20px;
						margin-top: 10px;
						height: 334px;
						.none-box {
							padding: 61px 0 96px 0;
						}
					}
					button {
						font-size: 14px;
						line-height: 17px;
						color: #000000;
						margin-top: 13px;
					}
				}
			}
			.club-wrap {
				margin-top: 20px;
				flex-wrap: wrap;
				gap: 10px;
				.club-box {
					width: 100%;
					background-color: #fff;
					border-radius: 10px;
					padding: 30px 20px 14px 20px;
					.club-title {
						font-size: 20px;
						line-height: 25px;
					}
					.club-list {
						margin-top: 30px;
						img {
							width: 67px;
						}
						.club-name {
							font-size: 12px;
							line-height: 15px;
						}
					}
					.allmore-line {
						border-top: 1px solid #eeeeee;
						margin-top: 40px;
					}
					button {
						font-size: 14px;
						line-height: 17px;
						color: #000000;
						margin: 13px 0 0 0;
					}
					.no-open {
						padding: 33px 0 53px 0;
					}
				}
			}
		}
		.none-club-wrap {
			position: relative;
			.none-club-box {
				height: 275px !important;
				border-radius: 10px !important;
				top: -922px !important;
				.no-open {
					padding: 90px 0;
					text-align: center;
					.none-text {
						font-weight: 300;
						font-size: 16px;
						line-height: 20px;
						margin-top: 20px;
						color: #888888;
					}
				}
			}
		}
	}
`;
