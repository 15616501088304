import MainContainer from "../../../components/MainContainer";
import Header from "../../../components/daitsoHeader";
import MarkIcon from "../../../vector/Mark";
import TriangleIcon from "../../../vector/TriangleIcon";
import { Wrapper } from "./styled";

export default function Global() {
  return (
    <>
      <MainContainer>
        <Wrapper>
          <div className="global-wrapper">
            <section className="cover-wrap">
              <div className="cover-box">
                <h2 className="cover-title">
                  2023
                  <br /> Company Introduction
                </h2>
                <div className="main-img-box">
                  <img src="main-img.png" alt="" />
                </div>
                <div className="logo-title-box">
                  <img src="KB_Signature.png" alt="" />
                </div>
                <div className="scroll-box">
                  <img src="icon-kb-scroll.png" alt="" />
                </div>
              </div>
            </section>
            <section className="content-wrap">
              <div className="first-box">
                <div className="circle"></div>
                <div className="logo-title-color">
                  <img src="KB_Signature-color.png" alt="" />
                </div>
                <i className="top-triangle">
                  <TriangleIcon />
                </i>
                <p className="content-text">
                  KB Data Systems is the representative financial IT / DT
                  professional corporate as an affiliate of KB Financial Group
                  with more than 30 years.
                </p>
                <i className="under-triangle">
                  <TriangleIcon />
                </i>
                <div className="kb-banner-box">
                  <img src="kb-banner.png" alt="" />
                </div>
              </div>
              <div className="second-box">
                <div className="circle-box">
                  <div className="big-circle"></div>
                  <div className="color-circle"></div>
                  <i className="second-triangle">
                    <TriangleIcon />
                  </i>
                  <div className="s-circle"></div>
                </div>
                <div className="second-text-box">
                  <p className="title">Greeting for global companion</p>
                  <p className="ceo-title">CEO Message</p>
                  <p className="desc">
                    KB Financial Group is a comprehensive financial group with
                    the largest customer base and offline branch network in
                    Korea. And also, it is a digital leading financial group
                    that has competitive financial platforms by affiliates such
                    as banks and securities cards and non-financial platforms
                    such as real estate, automobile and healthcare. <br />
                    <br />
                    As a affiliate of KB financial group, KB Data Systems
                    provides solutions and services for Digital and IT, which
                    are the core of finance. KB Data Systems is a financial IT
                    company that provides discriminatory values centered on
                    digital. By contributing to the group's digital innovation
                    based on its expertise in digital technology and financial
                    affairs such as Cloud, Big Data and A.I.
                    <br />
                    <br /> We will create a better world for the customers’
                    happier. Be always with You, KB Data Systems.
                  </p>
                </div>
                <div className="ceo-img-box">
                  <img src="ceo-img.png" alt="" className="ceo" />
                  <div className="ceo-intro">
                    <p className="ceo-title">
                      <strong>Kim, Myung Won</strong>
                      <br />
                      CEO of KB Data Systems
                    </p>
                    <img src="signature.png" alt="" className="signature" />
                  </div>
                </div>
              </div>
              <div className="history-wrap">
                <p className="title history-title">History</p>
                <div className="history-bg">
                  <ul className="history-list">
                    <li>
                      Indonesia Bukopin Bank & KB Cambodia IT project
                      <br />
                      <span>performing now</span>
                    </li>
                    <li>
                      2020
                      <br /> PT KBDS Indonesia established
                    </li>
                    <li>
                      2010’s ~ 2020
                      <br /> Thousands of The Next Generation
                      <br /> IT System project of KB Financial affiliates <br />
                      (KB Bank, KB Card, KB Capital etc)
                      <span> performed</span>
                    </li>
                    <li>
                      2013
                      <br /> Winning the Asian Banker Awards
                    </li>
                    <li>
                      2010
                      <br /> Kazakhstan BCC EDW Project <span>performed</span>
                    </li>
                    <li>
                      2008
                      <br /> KB Financial Group launches
                      <br />{" "}
                      <span>Became a affiliate of KB holding company</span>
                    </li>
                    <li>
                      2007
                      <br /> Vietnam Agri Bank External & Foreign currency
                      Project <span>performed</span>
                    </li>
                    <li>
                      2004
                      <br /> Indonesia BII Bank MIS Project{" "}
                      <span>performed</span>
                    </li>
                    <li>
                      2004
                      <br /> KB Data Systems <span>Inc. renamed</span>
                    </li>
                    <li>
                      1991
                      <br /> Kookmin Data Systems Inc. established
                      <br />{" "}
                      <span>
                        As a affiliated company of Kookmin Bank
                        <br /> with just 46 people
                      </span>
                    </li>
                  </ul>
                  <ul className="list-line">
                    <li className="line-circle"></li>
                    <li className="line-circle2"></li>
                    <li className="line-circle3"></li>
                    <li className="line-circle2"></li>
                    <li className="line-circle2"></li>
                    <li className="line-circle4"></li>
                    <li className="line-circle5"></li>
                    <li className="line-circle6"></li>
                    <li className="line-circle2"></li>
                  </ul>
                </div>
              </div>
              <div className="business-wrap">
                <p className="title">Business scope</p>
                <div className="business-banner">
                  <img src="Business-banner.png" alt="" />
                  <p className="banner-text">Digital</p>
                </div>
                <div className="business-text-box">
                  <p className="business-desc">
                    <span>MSP(Managed Service Provider) business</span>
                    <br />
                    For the first in Korean financial,
                    <br /> we’re in charge of both Public and Private cloud
                  </p>
                  <p className="business-desc under-desc">
                    <span>SaaS(Software as a service) business</span>
                    <br />
                    It provides SasS service within KB Financial group. We
                    developed SaaS engine and services contents.
                  </p>
                </div>
                <div className="business-banner">
                  <img src="Business-banner2.png" alt="" />
                  <p className="banner-text">
                    SM
                    <br /> (System Maintenance)
                  </p>
                </div>
                <div className="business-text-box">
                  <p className="business-desc under-desc">
                    <span>Reliability and Stability</span>
                    <br />
                    With rich know-how,
                    <br /> We provide reliable and stable IT system
                    <br /> maintenance service in 24H/365days
                  </p>
                </div>
                <div className="business-banner">
                  <img src="Business-banner3.png" alt="" />
                  <p className="banner-text">
                    SI
                    <br />
                    (System Integraion)
                  </p>
                </div>
                <div className="business-text-box">
                  <p className="business-desc under-desc">
                    <span>Solutions development</span>
                    <br />
                    Develop various solutions specialized in finance
                    <br /> We’ll show you the best IT systems
                    <br /> what you exactly imagined
                  </p>
                </div>
                <div className="business-banner">
                  <img src="Business-banner4.png" alt="" />
                  <p className="banner-text">
                    Global
                    <br /> IT Service
                  </p>
                </div>
                <div className="business-text-box">
                  <p className="business-desc under-desc">
                    <span>Compatibility and expandability</span>
                    <br />
                    With global network of KB Financial
                    <br /> and PT KBDS Indonesia,
                    <br /> we’ serving a variety of IT service in the world.
                  </p>
                </div>
                <div className="work-box">
                  <p className="work-text">
                    Transition from a workforce - oriented/cost-centric IT
                    affiliate of KB Financial group to digital oriented SW
                    Powerhouse.
                    <br /> With this we achieved more than tripled growth in the
                    last 10 years
                  </p>
                </div>
              </div>
              <div className="graph-img-box">
                <img src="graph-img.png" alt="" />
              </div>
              <div className="vision-wrap">
                <p className="title">Vision</p>
                <div className="vision-text-box">
                  <i className="top-mark">
                    <svg
                      width="22"
                      height="18"
                      viewBox="0 0 22 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.1061 0.748548C17.2293 0.628088 17.3506 0.628088 17.4738 0.748548C17.597 0.869009 17.597 0.989469 17.597 1.10993C16.8635 2.31453 16.0068 4.12144 15.5178 5.80788C15.5178 6.28972 15.5178 6.6511 15.8855 7.01248C16.13 7.37386 16.619 7.61478 16.9867 7.61478C19.7994 7.61478 22 9.78307 22 12.4332C22 15.0833 19.7994 17.2516 17.108 17.2516C14.4166 17.2516 12.216 15.0833 12.216 12.4332C12.216 8.09663 13.9275 3.88052 17.108 0.748548H17.1061ZM4.892 0.748548C5.01519 0.628088 5.13651 0.628088 5.25969 0.748548C5.38288 0.869009 5.38288 0.989469 5.38288 1.10993C4.64936 2.31453 3.79265 4.12144 3.30364 5.80788C3.30364 6.28972 3.30364 6.6511 3.67133 7.01248C3.91584 7.37386 4.40485 7.61478 4.77255 7.61478C7.58531 7.61478 9.78587 9.78307 9.78587 12.4332C9.78587 15.0833 7.58531 17.2516 4.89387 17.2516C2.20243 17.2516 0 15.0833 0 12.4332C0 8.09663 1.71155 3.88052 4.892 0.748548Z"
                        fill="#FFCB00"
                      />
                    </svg>
                  </i>
                  <p className="vision-text">
                    The most trusted financial IT/DT partner with the best
                    talent and bold innovation
                  </p>
                  <i className="under-mark">
                    <svg
                      width="22"
                      height="18"
                      viewBox="0 0 22 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.1061 0.748548C17.2293 0.628088 17.3506 0.628088 17.4738 0.748548C17.597 0.869009 17.597 0.989469 17.597 1.10993C16.8635 2.31453 16.0068 4.12144 15.5178 5.80788C15.5178 6.28972 15.5178 6.6511 15.8855 7.01248C16.13 7.37386 16.619 7.61478 16.9867 7.61478C19.7994 7.61478 22 9.78307 22 12.4332C22 15.0833 19.7994 17.2516 17.108 17.2516C14.4166 17.2516 12.216 15.0833 12.216 12.4332C12.216 8.09663 13.9275 3.88052 17.108 0.748548H17.1061ZM4.892 0.748548C5.01519 0.628088 5.13651 0.628088 5.25969 0.748548C5.38288 0.869009 5.38288 0.989469 5.38288 1.10993C4.64936 2.31453 3.79265 4.12144 3.30364 5.80788C3.30364 6.28972 3.30364 6.6511 3.67133 7.01248C3.91584 7.37386 4.40485 7.61478 4.77255 7.61478C7.58531 7.61478 9.78587 9.78307 9.78587 12.4332C9.78587 15.0833 7.58531 17.2516 4.89387 17.2516C2.20243 17.2516 0 15.0833 0 12.4332C0 8.09663 1.71155 3.88052 4.892 0.748548Z"
                        fill="#FFCB00"
                      />
                    </svg>
                  </i>
                </div>
              </div>
              <div className="vision-img-box">
                <img src="vision-img.png" alt="" />
                <p className="vision-img-title">
                  Transferring to the <span>“SW Powerhouse”</span> based on
                  <br />
                  digital technologies and financial IT know-how
                </p>
                <div className="graph-desc-box">
                  <p className="vision-img-text">
                    IT Cost Center of
                    <br /> KB Financial group
                  </p>
                  <p className="vision-img-text is-text">Digital / IT Hub</p>
                  <p className="vision-img-text sw-text">
                    SW
                    <br /> PowerHouse
                  </p>
                </div>
              </div>
              <div className="growth-wrap">
                <p className="title growth-title">Growth of last 10 years</p>
                <div className="growth-box">
                  <div className="total-wrap">
                    <p className="total-title">
                      Total
                      <br /> sales
                      <br /> <span>(USD$)</span>
                    </p>
                  </div>
                  <div>
                    <div className="growth-graph-box">
                      <p className="growth-title">60Million</p>
                      <div className="growth-graph1"></div>
                    </div>
                    <div className="growth-graph-box">
                      <p className="growth-title">91Million</p>
                      <div className="growth-graph2"></div>
                    </div>
                    <div className="growth-graph-box">
                      <p className="growth-title">179Million</p>
                      <div className="growth-graph3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Wrapper>
      </MainContainer>
    </>
  );
}
