import MainContainer from '../../../components/MainContainer';
import Header from '../../../components/daitsoHeader';
import AddPen from '../../../vector/Addpen';
import ArrowRightTop from '../../../vector/ArrowRightTop';
import BigArrow from '../../../vector/BigArrow';
import CrystalIcon from '../../../vector/CrystalIcon';
import HomeIcon from '../../../vector/IconHome';
import PenIcon from '../../../vector/IconPen';
import MenuBook from '../../../vector/MenuBook';
import SauareIcon from '../../../vector/SauareIcon';
import UnderArrow from '../../../vector/UnderArrow';
import { Wrapper } from './styled';
import QuestionIcon from '../../../vector/QuestionMark';
import { Swiper } from 'swiper/react';
import 'swiper/swiper.min.css';
import { CardSlide } from '../../Swiper/styled';
import ReactSwiper from '../../Swiper';
import TimeIcon from '../../../vector/TimeIcon';
import { PostSwiperWrapper } from '../../Swiper/PostSwiper/styled';
import ReactPostSwiper from '../../Swiper/PostSwiper';
import NoonIcon from '../../../vector/NoonIcon';
import NoneIcon from '../../../vector/NoonIcon';
import MohubArrow from '../../../vector/MohubArrow';
import { useState } from 'react';
import Modal1 from '../../Dashboard/mentor/modal/modal1';
import Modal3 from '../../Dashboard/mentor/modal/modal3';
import MentoringOn from '../../Dashboard/mentor/modal/mentoring-board';
import Currentcon from '../../../vector/Current';
import Mokbdsarrow from '../../../vector/MoKbsdArrow';
import MoManual from '../../../vector/MoManual';
import ManualIcon from '../../../vector/Manual';
import ManualArrowIcon from '../../../vector/ManualArrow';
import HubModal from '../../../components/Popup/CommunicationHub';

export default function Generaluser(user) {
	const [show, setShow] = useState(false);
	const modalToggle = () => {
		setShow((p) => !p);
	};
	const [show3, setShow3] = useState(false);
	const modalToggle3 = () => {
		setShow3((p) => !p);
	};
	const [show4, setShow4] = useState(false);
	const mentoringon = () => {
		setShow4((p) => !p);
	};

	return (
		<>
			<MainContainer>
				<Wrapper>
					<div className="my-wrapper">
						<div className="top-wrap">
							<h1 className="my-title">Profile</h1>
							<button>
								<i className="icon-home">
									<HomeIcon />
								</i>
								다it소 메인으로 가기
							</button>
						</div>
						<div className="mo-none-box">
							<div className="profile-wrap">
								<div className="left-box">
									<div className="profile-box">
										<div className="pro-wrap">
											<img src="profile-img.png" alt="" />
											<div className="profile-text-box">
												<p className="profile-department">경영기획부</p>
												<h1 className="profile-hello">
													<span>홍길동</span>
												</h1>
											</div>
										</div>
										<div className="my-info-box">
											<div className="email">
												<p className="list-title">Email</p>
												<p className="list-text mail-text">
													jhsuk@easypress.me
												</p>
											</div>
											<div className="phone">
												<p className="list-title">Phone</p>
												<p className="list-text">010-1234-5678</p>
											</div>
										</div>
									</div>
								</div>
								<div className="right-box">
									<div className="right-text-box">
										<h1 className="right-title">커뮤니케이션 HUB</h1>
										<p className="right-text">
											KBDS의 다양한 채널들을 소개합니다.
										</p>
										<div className="icon-box ">
											<i className="icon-under-arrow">
												<UnderArrow />
											</i>
											<i className="icon-sauare">
												<SauareIcon />
											</i>
										</div>
									</div>
									{/* <HubModal disabled={!show} /> */}
								</div>
							</div>
							<div className="under-box">
								<ul className="point-list">
									<li>
										<p className="point-title">누적포인트</p>
										<p className="point-text">52,040P</p>
									</li>
									<li>
										<p className="point-title">현재 포인트</p>
										<p className="point-text">30,100P</p>
									</li>
									<li>
										<p className="point-title">봉사시간</p>
										<p className="point-text">12시간</p>
									</li>
									<li>
										<p className="point-title">작성 포스트</p>
										<p className="point-text">53개</p>
									</li>
									<li>
										<p className="point-title">관심 포스트</p>
										<p className="point-text">3개</p>
									</li>
								</ul>
								<div
									className="faq-box"
									onClick={() =>
										window.open(
											'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
										)
									}
								>
									<div className="fap-icon-box">
										<i className="menubook-icon">
											<MenuBook />
										</i>
										<p className="faq-title">FAQ</p>
									</div>
									<i className="big-arrow">
										<BigArrow />
									</i>
								</div>
							</div>
						</div>
						{/* 모바일 */}
						<div className="mo-block-wrap">
							<div className="mo-pro-box">
								<div className="pro-wrap">
									<div className="profile-text-box">
										<p className="profile-department">경영기획부</p>
										<h1 className="profile-hello">
											<span>홍길동</span>님 안녕하세요!
										</h1>
									</div>
									<div></div>
								</div>
								<div className="my-info-box">
									<div className="email">
										<p className="list-title">Email</p>
										<p className="list-text mail-text">jhsuk@easypress.me</p>
									</div>
									<div className="phone">
										<p className="list-title">Phone</p>
										<p className="list-text">010-1234-5678</p>
									</div>
								</div>
							</div>
							<div className="mo-under-box">
								<div className="under-wrap">
									<ul className="point-list">
										<li>
											<p className="point-title">누적포인트</p>
											<p className="point-text">52,040P</p>
											<p className="acc-title">(누: 52,040P)</p>
										</li>

										<li>
											<p className="point-title">봉사시간</p>
											<p className="point-text">12시간</p>
										</li>
										<li>
											<p className="point-title">작성 포스트</p>
											<p className="point-text">53개</p>
										</li>
										<li>
											<p className="point-title">관심 포스트</p>
											<p className="point-text">3개</p>
										</li>
									</ul>
									<div className="right-box">
										<div className="right-text-box">
											<h1 className="right-title">커뮤니케이션 HUB</h1>
											<p className="right-text">
												KBDS의 다양한 채널들을 소개합니다.
											</p>
											<div className="add-box">
												<button>
													<i className="icon-add-pen">
														<AddPen />
													</i>
													새 멘티 추가
												</button>
											</div>
											<div className="icon-box">
												<i className="icon-under-arrow">
													<MohubArrow />
												</i>
												<i className="icon-sauare">
													<SauareIcon />
												</i>
											</div>
										</div>

										<div className="faq-box">
											<div className="fap-icon-box">
												<i className="menubook-icon">
													<MenuBook />
												</i>
												<p className="faq-title">FAQ</p>
											</div>
											<i className="big-arrow">
												<BigArrow />
											</i>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* 모바일 end */}
						{user?.role == 'mentee' ? (
							<div className="mentee-wrap">
								<div className="mentee-left">
									<div className="mentor-top">
										<div className="mentor-left">
											<h1 className="mentor-top-title">멘토링 현황</h1>
											<div className="mentor-border">멘티</div>
										</div>
										<div className="mentor-right">
											<button>
												<i className="kbds-arrow">
													<ArrowRightTop />
												</i>
												패들릿으로 가기
											</button>
										</div>
									</div>
									<div className="current-wrap">
										<div className="noti-txt">
											<img
												src="../images/icon-park-outline_attention.svg"
												alt=""
											/>
											<p>
												멘토링 활동이 저조합니다. 멘토링 활동은
												<span>1개월 1회 이상</span>
												권장합니다.
											</p>
										</div>
										{/* <ul className="current-list">
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">1회차</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">2회차</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li>
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">3회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">4회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">5회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
									</ul> */}
										<ul className="current-list-three">
											<li className="on">
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">1회차</p>
												<div className="round-box1 complete-box">
													<p className="schedule-text complete-text">
														complete
													</p>
												</div>
											</li>
											<li className="on">
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">2회차</p>
												<div className="round-box1 complete-box">
													<p className="schedule-text complete-text">
														complete
													</p>
												</div>
											</li>
											<li>
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">3회차</p>
												<div className="round-box1">
													<p className="schedule-text">예정</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="mentee-right">
									<h1 className="mentee-title">신규입사자 매뉴얼</h1>
									<p className="mentee-text">
										KBDS의 다양한 채널들을 소개합니다.
									</p>

									<div className="manual-icon-box">
										<div className="manual-arrow-box">
											<i className="manual-arrow">
												<ManualArrowIcon />
											</i>
										</div>
										<i className="manual-icon">
											<ManualIcon />
										</i>
									</div>
								</div>
							</div>
						) : null}
						{/* 모바일 */}
						{user?.role == 'mentee' ? (
							<div className="mo-mentee-wrap">
								<div className="mentee-top">
									<div className="manual-box">
										<i className="mo-manual-icon">
											<MoManual />
										</i>
										<h1 className="mentee-title">신규입사자 매뉴얼</h1>
									</div>
									<i className="big-arrow">
										<BigArrow />
									</i>
								</div>
								<div className="mentee-under">
									<div className="mentor-top">
										<div className="mentor-left">
											<h1 className="mentor-top-title">멘토링 현황</h1>
											<div className="mentor-border">멘티</div>
										</div>
										<div className="mentor-right">
											<button>
												<i className="kbds-arrow">
													<Mokbdsarrow />
												</i>
												패들릿으로 가기
											</button>
										</div>
									</div>
									<div className="current-wrap">
										<div className="noti-txt-box">
											<div className="noti-txt">
												<img
													src="../images/icon-park-outline_attention.svg"
													alt=""
												/>
												<p>
													멘토링 활동이 저조합니다.
													<br /> 멘토링 활동은
													<span>1개월 1회 이상</span>권장합니다.
												</p>
											</div>
										</div>
										{/* <ul className="current-list">
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">1회차</p>
											<p className="date">
												2022.01.14 <span>(수)</span>
											</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">2회차</p>
											<p className="date">
												2022.03.14 <span>(수)</span>
											</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li>
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">3회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">4회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">5회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
									</ul> */}
										<ul className="current-list-three">
											<li className="on">
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">1회차</p>
												<p className="date">
													2022.01.14 <span>(수)</span>
												</p>
												<div className="round-box1 complete-box">
													<p className="schedule-text complete-text">
														complete
													</p>
												</div>
											</li>
											<li className="on">
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">2회차</p>
												<p className="date">
													2022.03.14 <span>(수)</span>
												</p>
												<div className="round-box1 complete-box">
													<p className="schedule-text complete-text">
														complete
													</p>
												</div>
											</li>
											<li>
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">3회차</p>
												<div className="round-box1">
													<p className="schedule-text">예정</p>
												</div>
											</li>
										</ul>
									</div>
									<div className="more-box">
										<button>모두 보기</button>
									</div>
									<div className="gradient-box">
										<div className="bg-l"></div>
										<div className="bg-r"></div>
									</div>
								</div>
							</div>
						) : null}
						<div className="post-title-wrap">
							<div className="post-title-box">
								<h2 className="post-title">작성 포스트</h2>
							</div>
						</div>
						<div className="post-wrap">
							<div className="post-box">
								<div className="writing-wrap">
									<ReactPostSwiper />
									<div className="post-line"></div>
									<button>모두 보기</button>
								</div>
							</div>
						</div>
						<div className="club-wrap">
							<div className="club-box">
								<h1 className="club-title">동호회</h1>
								<div className="club-list">
									<div className="list-box">
										<img src="club1.png" alt="" />
										<p className="club-name">자전거 동호회</p>
									</div>
									<div className="list-box">
										<img src="club2.png" alt="" />
										<p className="club-name">테니스 동호회</p>
									</div>
									<div className="list-box">
										<img src="club3.png" alt="" />
										<p className="club-name">자전거 동호회</p>
									</div>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button>
							</div>
							<div className="club-box">
								<h1 className="club-title">COP</h1>
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button>
							</div>
							<div className="club-box">
								<h1 className="club-title">소모임</h1>
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button>
							</div>
						</div>
						<div className="none-club-wrap">
							<div className="none-club-box">
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
							</div>
						</div>
					</div>
				</Wrapper>
			</MainContainer>
			{show ? <Modal1 modalToggle={modalToggle} /> : ''}
			{show3 ? <Modal3 modalToggle3={modalToggle3} /> : ''}
			{show4 ? <MentoringOn mentoringon={mentoringon} /> : ''}
		</>
	);
}
