export default function TriangleIcon() {
  return (
    <svg
      width="29"
      height="11"
      viewBox="0 0 29 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 5.5L2.75 10.2631L2.75 0.73686L11 5.5Z" fill="#FFBC00" />
      <path d="M20 5.5L11.75 10.2631L11.75 0.73686L20 5.5Z" fill="#FFBC00" />
      <path d="M29 5.5L20.75 10.2631L20.75 0.73686L29 5.5Z" fill="#FFBC00" />
    </svg>
  );
}
