export default function TimeIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08471 0.351562C3.62438 0.351562 0.822266 3.27741 0.822266 6.88246C0.822266 10.4875 3.62438 13.4134 7.08471 13.4134C10.5513 13.4134 13.3597 10.4875 13.3597 6.88246C13.3597 3.27741 10.5513 0.351562 7.08471 0.351562ZM7.09098 12.1072C4.32021 12.1072 2.07601 9.76912 2.07601 6.88246C2.07601 3.9958 4.32021 1.65774 7.09098 1.65774C9.86175 1.65774 12.1059 3.9958 12.1059 6.88246C12.1059 9.76912 9.86175 12.1072 7.09098 12.1072ZM7.40441 3.61701H6.46411V7.53555L9.75518 9.59278L10.2253 8.78948L7.40441 7.04573V3.61701Z"
        fill="white"
      />
    </svg>
  );
}
