import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GetMenteeInfo, GetUserPosts } from '../../../api/story';
import HubModal from '../../../components/Popup/CommunicationHub';
import FaqIcon from '../../../vector/FaqIcon';
import HubClose from '../../../vector/HubClose';
import HubOpen from '../../../vector/HubOpen';
import LinkBtn from '../../../vector/LinkSquare';
import MailIcon from '../../../vector/MailIcon';
import PhoneIcon from '../../../vector/PhoneIcon';
import RightArrow from '../../../vector/RightArrow';
import SettingIcon from '../../../vector/SettingIcon';
import SmallBookIcon from '../../../vector/SmallBookIcon';
import SmallSettingIcon from '../../../vector/SmallSettingIcon';
import WhitePencilIcon from '../../../vector/WhitePencilIcon';

const MobileButtonWrapper = styled.div`
	@media (max-width: 768px) {
		display: block;
	}
	@media (min-width: 769px) {
		display: none;
	}
	button {
		background-color: transparent;
		border: 2px solid black;
		width: 24%;
		border-radius: 88px;
	}
	.edit-profile-btn {
		z-index: 999;
		padding-top: 6px;
		position: absolute;
		right: 4%;
		top: 12px;
		bottom: -90px;
		border: 1px solid #e9e9e9;
		border-radius: 50%;
		height: 50px;
		width: 50px;
	}
	.mobile-manual {
		z-index: 999;
		padding-top: 6px;
		position: absolute;
		right: 18%;
		top: 12px;
		bottom: -90px;
		border: 1px solid #e9e9e9;
		border-radius: 50%;
		height: 50px;
		width: 50px;
		margin-right: 10px;
	}
	.main-page-btn {
		display: none;
	}
`;

const TopSectionWrapper = styled.div`
	a {
		color: black;
		text-decoration: none;
	}
	.button-wrapper {
		position: absolute;
		right: 0;
		bottom: 50px;
		@media (max-width: 768px) {
			display: none;
		}

		.pc-manual {
			margin-bottom: 15px;
			border: 1px solid #e9e9e9;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.02em;
			color: #333333;
			padding: 0 42px;
			:hover {
				background: #efefef;
				transition: 0.1s ease-out;
			}
		}
		.profile-edit-btn {
			margin-bottom: 15px;
			border: 1px solid #e9e9e9;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.02em;
			color: #333333;
			:hover {
				background: #efefef;
				transition: 0.1s ease-out;
			}
		}
		.new-post-btn {
			color: white;
			background: black;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.03em;
			border: none;
			:hover {
				background: #000000c1;
				transition: 0.1s ease-out;
			}
		}
		button {
			background-color: transparent;
			border: 2px solid black;
			padding: 14px 20px;
			width: 200px;
			height: 62px;
			border-radius: 88px;
			display: flex;
			align-items: center;
			text-align: center;
			margin: 0 auto;
			vertical-align: middle;
			span {
				margin: 0 auto;
			}
			i {
				padding-top: 4px;
				margin-right: 8px;
			}
		}
	}
	display: flex;
	width: 100%;
	max-width: 1400px;
	margin: 30px auto;

	position: relative;
	@media (max-width: 768px) {
		display: block;
		padding-top: 24px;
		margin-top: 0;
	}

	div {
		position: relative;
		border-radius: 28px;
		color: black;
		@media (max-width: 768px) {
			border-radius: 10px;
		}
	}

	span {
		display: block;
		margin: 8px 0;
		strong {
			font-weight: 900;
		}
	}

	.profile {
		position: relative;
		width: 70%;
		background-color: white;
		padding: 50px;
		display: flex;
		flex-direction: column;
		max-height: 308px;
		@media (max-width: 768px) {
			padding: 20px;
			min-height: 190px;
			width: 100%;
			margin: auto;
		}
		.person-info {
			display: flex;
			align-items: center;
			.avatar {
				img {
					width: 80px;
					height: 80px;
					border-radius: 50%;
					@media (max-width: 768px) {
						width: 50px;
						height: 50px;
					}
				}
			}
			.user-data {
				margin-left: 32px;
				@media (max-width: 768px) {
					margin-left: 14px;
					margin-top: 2px;
				}

				.department {
					font-weight: 300;
					font-size: 22px;
					color: #686868;
					@media (max-width: 768px) {
						font-size: 14px;
					}
				}
				.name {
					margin-top: 16px;
					font-size: 32px;
					letter-spacing: -0.02em;
					@media (max-width: 768px) {
						font-size: 20px;
					}
				}
				.user-description {
					position: absolute;
					font-weight: 300;
					font-size: 16px;
					line-height: 18px;
					color: #686868;
					width: 490px !important;
					@media (max-width: 768px) {
						width: 140px !important;
						font-size: 11px;
						display: flex;
						top: 20px;
						left: 62px;
					}
				}
			}
		}

		.contact_box {
			position: relative;
			display: flex;
			justify-content: space-between;

			@media (max-width: 768px) {
				margin-top: 48px;
			}
			.contact-info {
				padding-top: 200px;
				@media (max-width: 768px) {
					padding-top: 0;
				}
			}

			.contact {
				position: absolute;
				display: block;
				bottom: 50px;
				@media (max-width: 768px) {
					bottom: -42px;
					margin-left: 12px;
				}
				.contact-detail {
					display: flex;
					vertical-align: middle;
					align-items: center;
					i {
						margin-right: 16px;
					}

					span {
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						@media (max-width: 768px) {
							line-height: 14px;
							font-size: 14px;
						}
					}
					.tel-number {
						margin-right: 14px !important;
					}
				}
			}
		}
	}

	.right {
		width: 30%;
		margin-left: 30px;
		padding: 40px;
		background-color: #f9dd4a;
		position: relative;
		min-height: 300px;

		:hover {
			background-color: #f9dc4ac1;
			transition: 0.1s ease-out;
		}
		@media (max-width: 768px) {
			margin-top: 12px;
			margin-right: 0px;
			width: 69%;
			min-width: 100px;
			position: absolute;
			right: 0;
			min-height: 244px;
			padding: 20px;
		}

		.hub-btn {
			background-color: transparent;
			border: none;
			cursor: pointer;
			position: absolute;
			bottom: 40px;
			left: 40px;
			padding-top: 6px;
			@media (max-width: 768px) {
				left: 13px;
				bottom: 15px;
			}
		}
		.link-btn {
			background-color: transparent;
			border: none;
			position: absolute;
			right: 40px;
			bottom: 40px;
			@media (max-width: 768px) {
				right: 10px;
				bottom: 10px;
			}
		}
		h1 {
			font-weight: 700;
			font-size: 27px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 18px;
			}
		}
		span {
			font-weight: 300;
			font-size: 18px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 10px;
			}
		}
	}

	.faq {
		background-color: #736c61;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		min-height: 96px;
		max-height: 96px;
		padding: 28px 40px 28px 34px;
		:hover {
			background: rgba(45, 42, 38, 0.6);
			transition: 0.1s ease-out;
			i {
				transform: translateX(20%);
				transition: 0.1s ease-out;
			}
		}
		@media (max-width: 768px) {
			width: 69%;
			min-height: 70px;
			position: absolute;
			top: 224px;
			z-index: 999;
			align-items: center;
		}
		i:last-child {
			right: 38px;
			@media (max-width: 768px) {
				right: 18px;
			}
		}
		span {
			color: white;
			font-weight: 700;
			font-size: 24px;
			line-height: 30px;
			margin-right: 48%;
			@media (max-width: 768px) {
				position: absolute;
				left: 20px;
				align-items: center;
			}
			i {
				margin-right: 12px;
				@media (max-width: 768px) {
					margin-right: 10px;
				}
			}
		}
		.rightarr-btn {
			position: absolute;
			right: 20px;
		}
	}
	.hub-btn-dnjdlek {
		float: right;
		background-color: transparent;
		border: none;
		:hover {
			transform: translateX(4px) translateY(-4px);
			transition: 0.1s ease-out;
		}
	}

	.right {
		opacity: 1;
		transition: 0.45s;
	}
	.right.hidden {
		opacity: 0;
	}
	.dnjdlek.show {
		opacity: 1;
		z-index: 8989;
	}
	.dnjdlek {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		width: 624px;
		height: 548px;
		margin-left: 24px;
		min-width: 330px;
		padding: 32px;
		padding-top: 0;
		background-color: #f9dd4a;
		transition: 0.45s;
		opacity: 0;
		min-height: 300px;
		overflow-y: scroll;
		::-webkit-scrollbar {
			display: none;
		}
		@media (max-width: 768px) {
			margin-top: 12px;
			margin-right: 0px;
			width: 69%;
			min-width: 100px;
			position: absolute;
			right: 0;
			min-height: 270px;
			padding: 24px;
		}
		.modal-head {
			position: sticky;
			top: 0;
			background-color: #f9dd4a;
			z-index: 999999;
			padding-top: 30px;
			border-radius: 0;
			border-bottom: 1px solid #00000033;
		}

		.hub-btn {
			border: none;
			cursor: pointer;
			position: absolute;
			bottom: 20px;
			left: 30px;
			padding-top: 6px;
			@media (max-width: 768px) {
				transform: translateX(-20%);
			}
		}
		.link-btn {
			background-color: transparent;
			border: none;
			position: absolute;
			right: 32px;
			bottom: 20px;
			@media (max-width: 768px) {
				right: 14px;
				bottom: 14px;
			}
		}
		h1 {
			font-weight: 700;
			font-size: 32px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 20px;
			}
		}
		span {
			font-weight: 300;
			font-size: 18px;
			line-height: 35px;
			letter-spacing: -0.03em;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}
	}
	@media (max-width: 768px) {
		.dnjdlek {
			width: 100% !important;
			top: 214px;
			padding-top: 0;
		}
	}

	.profile-info {
		position: relative;
		width: 70%;
		background-color: white;
		text-align: center;
		display: flex;
		padding: 24px 0;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 768px) {
			flex-direction: column;
			padding: 4px 0;
			width: 28%;
			position: absolute;
			top: -18px;
			min-height: 250px !important;
		}
		div:first-child {
			@media (max-width: 768px) {
				display: none;
			}
		}

		.info-detail {
			.border {
				border-right: 1px solid #eeeeee;
				height: 46px;
				position: absolute;
				left: 100%;
				top: 0;
				@media (max-width: 768px) {
					display: none;
				}
			}
			width: 100%;
			@media (max-width: 768px) {
				padding: 11px 0;
			}
			span:first-child {
				font-weight: 300;
				font-size: 14px;
				letter-spacing: -0.02em;
				color: #666666;
			}
			span:nth-child(2) {
				font-weight: 700;
				font-size: 16px;
				color: #000000;
				@media (max-width: 1600px) {
					font-size: 14px;
					fon-weight: 600;
				}
			}
			span:nth-child(3) {
				font-weight: 300;
				font-size: 14px;
				letter-spacing: -0.02em;
				color: #666666;
				@media (min-width: 769px) {
					display: none;
				}
			}
		}
	}
`;

export default function TopSection() {
	const [hubModal, setHubModal] = useState(false);
	const [menteeInfo, setMenteeInfo] = useState();
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	const [posts, setPosts] = useState([]);
	const user = useSelector((state) => state.auth.user_data);
	useEffect(() => {
		GetUserPosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			post_author: user.ID,
			paged: 1,
		})
			.then((res) => {
				setPosts(res.data.body.posts);
			})
			.catch((err) => console.log('포스트 정보 오류', err));
	}, [user.ID]);
	useEffect(() => {
		GetMenteeInfo().then((res) => {
			setMenteeInfo(res.data.body?.result);
		});
	}, []);

	useEffect(() => {
		user && setLoading(false);
	}, [user]);

	return (
		<>
			<TopSectionWrapper>
				<MobileButtonWrapper>
					<button
						className="edit-profile-btn"
						onClick={() =>
							window.open(
								`${process.env.REACT_APP_BACK_URI + '/wp-admin/profile.php'}`
							)
						}
					>
						<i>
							<SettingIcon />
						</i>
					</button>
				</MobileButtonWrapper>
				<div className="profile">
					<div className="person-info">
						<div className="avatar">
							<img
								src={
									user.user_avatar_url
										? user.user_avatar_url
										: 'https://secure.gravatar.com/avatar/?s=96&d=mm&r=g'
								}
								alt=""
							/>
						</div>
						<div className="user-data">
							<span className="department">{user.user_department}</span>
							<span className="name">
								<strong>{user?.user_name}</strong>
							</span>
							<span className="user-description">{user?.description}</span>
						</div>
					</div>

					<div className="contact_box">
						<div className="contact-info">
							{loading ? null : (
								<div className="contact">
									<div className="contact-detail">
										<i>
											<MailIcon />
										</i>
										<span>
											<a href={`mailto:${user.user_email}`}>
												{user.user_email
													? user.user_email
													: '등록된 메일이 없습니다.'}
											</a>
										</span>
									</div>
									<div className="contact-detail">
										<i>
											<PhoneIcon />
										</i>
										<span>
											<a href={`tel:${user.user_phone}`}>
												{user.user_phone
													? user.user_phone
													: '등록된 번호가 없습니다.'}
											</a>
										</span>
									</div>
								</div>
							)}
						</div>

						<div className="button-wrapper">
							{!user?.role ? (
								<button
									className="pc-manual"
									onClick={() =>
										window.open(
											'https://padlet.com/kbdsstarters/b7w3ma8sfhcw44kf'
										)
									}
								>
									<i>
										<SmallBookIcon />
									</i>
									<span>KBDS WIKI</span>
								</button>
							) : null}
							<button
								className="profile-edit-btn"
								onClick={() =>
									window.open(
										`${
											process.env.REACT_APP_BACK_URI + '/wp-admin/profile.php'
										}`
									)
								}
							>
								<span>
									<i>
										<SmallSettingIcon />
									</i>
									내 정보 수정
								</span>
							</button>
							<button
								className="new-post-btn"
								onClick={() =>
									window.open(
										'https://wp.daitso.kbds.co.kr/wp-admin/post-new.php'
									)
								}
							>
								<span>
									<i>
										<WhitePencilIcon />
									</i>
									글 작성하기
								</span>
							</button>
						</div>
					</div>
				</div>
				<div className={show ? 'right hidden' : 'right'}>
					<h1>커뮤니케이션 HUB</h1>
					<span>KB데이터시스템의 다양한 채널들을 소개합니다.</span>
					<button className="hub-btn" onClick={() => setShow((p) => !p)}>
						<i className="right-arr">
							<HubOpen />
						</i>
					</button>
					<button className="link-btn">
						<LinkBtn />
					</button>
				</div>
				<div className={show ? 'dnjdlek show' : 'dnjdlek'}>
					<div className="modal-head">
						<h1>
							커뮤니케이션 HUB
							<button
								className="hub-btn-dnjdlek"
								onClick={() => setShow((p) => !p)}
							>
								<HubClose />
							</button>
						</h1>
						<span>KB데이터시스템의 다양한 채널들을 소개합니다.</span>
					</div>
					<HubModal disabled={!show} />
				</div>
			</TopSectionWrapper>

			<TopSectionWrapper>
				<div className="profile-info">
					<div className="info-detail">
						<span>누적 포인트</span>
						<span>{user ? user.user_point_total : ''}p</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>현재 포인트</span>
						<span>{user ? user.user_point : ''}p</span>
						<span>(누: {user ? user.user_point_total : ''}p)</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>봉사시간</span>
						<span>{`${user ? user.volunteerTime : ''}시간`}</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>작성 포스트</span>
						<span>{`${posts ? posts.length : ''}개`}</span>
						<div className="border" />
					</div>
					<div className="info-detail">
						<span>관심 포스트</span>
						<span>{`${user ? user.interestPost?.length : ''}개`}</span>
					</div>
				</div>

				<div
					onClick={() =>
						window.open(
							'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
						)
					}
					className="right faq"
				>
					<span>
						<i>
							<FaqIcon />
						</i>
						FAQ
					</span>
					<i className="rightarr-btn">
						<RightArrow />
					</i>
				</div>
			</TopSectionWrapper>
		</>
	);
}
