import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #c4bdb5;
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffbc00;
  }
  .global-wrapper {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .cover-wrap {
      position: relative;
      z-index: 1;
      background-color: #ffcb00;
      .cover-box {
        padding: 0 20px;
      }
      .cover-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        padding: 160px 23px 0 23px;
      }
      .main-img-box {
        width: 320px;
        margin: 60px auto;
        img {
          width: 100%;
        }
      }
      .logo-title-box {
        width: 210px;
        margin: 20px auto;
        img {
          width: 100%;
        }
      }
      .scroll-box {
        width: 24px;
        margin: 0 auto;
        padding: 44px 0 60px 0;
        position: relative;
        bottom: 0;
        animation: down 3s infinite;
        img {
          width: 100%;
        }
        @keyframes down {
          50% {
            bottom: 5px;
          }
        }
      }
    }
    .content-wrap {
      background-color: #fff;
      .first-box {
        position: relative;
        .circle {
          width: 231px;
          height: 231px;
          border: 1px dashed #ffbc00;
          border-radius: 50%;
          position: absolute;
          left: 230px;
          top: -82px;
          &::after {
            content: "";
            position: absolute;
            top: 189px;
            left: 26px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #ffbc00;
            opacity: 0.6;
          }
        }
        .logo-title-color {
          width: 233px;
          margin: 0 auto;
          position: absolute;
          z-index: 1;
          top: 38px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          img {
            width: 100%;
          }
        }
        .top-triangle {
          display: block;
          padding-top: 136px;
          margin-left: 20px;
        }
        .content-text {
          padding: 30px 20px 20px 20px;
          font-weight: 500;
          font-size: 19px;
          line-height: 24px;
          letter-spacing: -0.03em;
        }
        .under-triangle {
          display: flex;
          justify-content: end;
          margin-right: 40px;
        }
        .kb-banner-box {
          margin-top: 18px;
          position: relative;
          z-index: 2;
          img {
            width: 100%;
          }
        }
      }
      .second-box {
        position: relative;
        .circle-box {
          border: 1px solid transparent;
          .big-circle {
            width: 253px;
            height: 253px;
            border: 1px dashed #ffbc00;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            left: -95px;
            top: -99px;
          }
          .color-circle {
            position: absolute;
            top: 40px;
            left: 106px;
            width: 82px;
            height: 82px;
            border-radius: 50%;
            background-color: #f7f7f7;
          }
          .second-triangle {
            position: absolute;
            top: 37px;
            right: 72px;
          }
          .s-circle {
            width: 151px;
            height: 151px;
            border: 1px dashed #ffbc00;
            border-radius: 50%;
            position: absolute;
            top: -70px;
            left: 268px;
          }
        }
        .second-text-box {
          margin-top: 167px;
          padding: 0 25px;
          .ceo-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            margin-top: 13px;
          }
          .desc {
            margin-top: 39px;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
          }
        }
        .ceo-img-box {
          margin-top: 23px;
          display: flex;
          .ceo {
            width: 239px;
            height: 352px;
          }
          .ceo-intro {
            position: relative;
            top: 118px;
            .ceo-title {
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              margin-left: -27px;
            }
            .signature {
              width: 68px;
              position: absolute;
              right: 25px;
            }
          }
        }
      }
      .history-wrap {
        margin-top: 114px;
        .history-title {
          margin-left: 25px;
          margin-bottom: 20px;
        }
        .history-bg {
          background-color: #f7f7f7;
          padding: 23px 25px 35px 25px;
          position: relative;
          .history-list {
            position: relative;
            z-index: 1;
            li {
              background-color: #fff;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;
              padding: 13px 10px 12px 11px;
              text-align: center;
              font-weight: 700;
              font-size: 12px;
              line-height: 15px;
              text-align: center;
              letter-spacing: -0.02em;
              margin-top: 57px;
              span {
                font-weight: 300;
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
          .list-line {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            li {
              width: 19px;
              height: 19px;
              border-radius: 50%;
              border: 4px solid #ffbc00;
              background-color: #fff;
              position: relative;
              z-index: 1;
            }
            .line-circle {
              margin-top: 100px;
              &::after {
                position: absolute;
                content: "";
                top: 11px;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 1000px;
                border-left: 2px dashed #ffbc00;
              }
            }
            .line-circle2 {
              margin-top: 92px;
            }
            .line-circle3 {
              margin-top: 123px;
            }
            .line-circle4 {
              margin-top: 110px;
            }
            .line-circle5 {
              margin-top: 106px;
            }
            .line-circle6 {
              margin-top: 96px;
            }
          }
        }
      }
      .business-wrap {
        padding: 117px 25px 0px 25px;
        .business-banner {
          margin-top: 23px;
          position: relative;
          img {
            width: 100%;
          }
          .banner-text {
            position: absolute;
            bottom: 34px;
            left: 27px;
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
            color: #ffffff;
          }
        }
        .business-text-box {
          margin-top: 21px;
          padding: 0 22px;
          .business-desc {
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            span {
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
            }
          }
          .under-desc {
            margin-top: 21px;
            padding-bottom: 21px;
          }
        }
        .work-box {
          margin-top: 157px;
          .work-text {
            font-weight: 500;
            font-size: 19px;
            line-height: 24px;
            letter-spacing: -0.02em;
          }
        }
      }
      .graph-img-box {
        margin-top: 30px;
        img {
          width: 100%;
        }
      }
      .vision-wrap {
        margin-top: 90px;
        padding: 0 31px 0 38px;
        .vision-text-box {
          margin-top: 20px;
          .vision-text {
            font-style: italic;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            padding: 0 27px;
          }
          .under-mark {
            display: flex;
            justify-content: end;
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      .vision-img-box {
        margin-top: 18px;
        position: relative;
        img {
          width: 100%;
        }
        .vision-img-title {
          position: absolute;
          width: 100%;
          top: 43px;
          left: 50px;
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          span {
            color: #ffcb00;
          }
        }
        .graph-desc-box {
          display: flex;
          padding-left: 26px;
          .vision-img-text {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: -0.02em;
            position: relative;
            top: -130px;
          }
          .is-text {
            margin-left: 15px;
          }
          .sw-text {
            margin-left: 29px;
            margin-top: -15px;
          }
        }
      }
      .growth-wrap {
        margin-top: 60px;
        padding: 0 25px;
        .growth-title {
          margin-left: 13px;
        }
        .growth-box {
          display: flex;
          margin-top: 44px;
          height: 177px;
          border-bottom: 1px solid red;
          .total-wrap {
            border-right: 1px solid red;
            .total-title {
              font-weight: 700;
              font-size: 13px;
              line-height: 17px;
              text-align: center;
              padding-right: 16px;
              span {
                font-size: 11px;
                line-height: 14px;
                color: #707070;
                margin-top: 7px;
              }
            }
          }
        }
      }
    }
  }

  @media ${(props) => props.theme.mobile} {
  }
`;
