import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import Auth from './components/Auth';
import useScript from './components/UseScripts';
import MyPage from './views/MyPage';
import ProfilePage from './views/ProfilePage';
import Story from './views/Story';
import Archive from './views/Story/Archive';
import SearchPage from './views/Story/Search';
import Single from './views/Story/Single';
import TagPage from './views/Story/Tag/index';
import User from './views/User';
import Test1 from './views/Dashboard/mentor';
import Test2 from './views/Dashboard/mentee';
import Test3 from './views/Dashboard/department';
import Test4 from './views/Dashboard/head-department';
import Test5 from './views/Dashboard/depart';
import Mypage2 from './views/MyPage/Mypage2';
import Mypageactivation from './views/Dashboard/mypage-activation';
import Mentee from './views/Dashboard/mentee-activation';
import Global from './views/Dashboard/Global';
import Generaluser from './views/Dashboard/general-user';
import Card from './views/Dashboard/mentor/modal/card';
import Card2 from './views/Dashboard/mentor/modal/card2';
import Group from './views/Group';

function App() {
	useScript();
	return (
		<>
			<Helmet>
				<title>다잇소</title>
			</Helmet>
			<Switch>
				<Route exact={true} path="/feed" component={Story} />
				<Route exact={true} path="/mentoring/admin" component={Test1} />
				<Route exact={true} path="/test2" component={Test2} />
				<Route exact={true} path="/mentoring/graduate" component={Test3} />
				<Route exact={true} path="/mentoring/:ID" component={Test5} />
				<Route exact={true} path="/group/:ID" component={Group} />

				{/* <Route exact={true} path="/mentoring/leader" component={Test4} /> */}
				{/* <Route exact={true} path="/mypage2" component={Mypage2} /> */}
				<Route
					exact={true}
					path="/mypageactivation"
					component={Mypageactivation}
				/>
				<Route exact={true} path="/mentee" component={Mentee} />
				<Route exact={true} path="/global" component={Global} />
				<Route exact={true} path="/generaluser" component={Generaluser} />
				<Route exact={true} path="/auth/:token" component={Auth} />
				<Route exact={true} path="/auth/:token/:redirect" component={Auth} />
				<Route exact={true} path="/cate/:id" component={Archive} />
				<Route exact={true} path="/story/:id" component={Single} />
				<Route exact={true} path="/search/:keyword" component={SearchPage} />
				<Route exact={true} path="/tag/:tag_slug" component={TagPage} />
				<Route exact={true} path="/user/:id" component={User} />
				<Route exact={true} path="/" component={Mypage2} />
				<Route exact={true} path="/:id" component={ProfilePage} />
				<Route exact={true} path="/business/card" component={Card} />
				<Route exact={true} path="/card/:id" component={Card2} />
			</Switch>
		</>
	);
}

export default App;
