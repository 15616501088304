import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import "swiper/css";
import 'swiper/swiper.min.css';
import { PostSwiperWrapper } from './styled';
import { useEffect, useState } from 'react';
import More from '../../Dashboard/mentor/modal/more';
import Crystal2 from '../../Dashboard/mentor/modal/crystal2';
import { useHistory, useParams } from 'react-router-dom';
import { GetLikePosts, GetUserPosts } from '../../../api/story';
import { useSelector } from 'react-redux';

const ReactLikeSwiper = (props) => {
	const [show7, setShow7] = useState(false);
	const moreToggle = () => {
		setShow7((p) => !p);
	};
	const [show10, setShow10] = useState(false);
	const crystalToggle2 = () => {
		setShow10((p) => !p);
	};

	const [posts, setPosts] = useState([]);
	const history = useHistory();
	const user = useSelector((state) => state.auth.user_data);
	const params = useParams();

	useEffect(() => {
		GetLikePosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			paged: 1,
		})
			.then((res) => {
				setPosts(res.data.body.posts);
				props.setPosts2(res.data.body.posts);
			})
			.catch((err) => console.log('포스트 정보 오류', err));
	}, [user.ID]);

	const seeAllPosts = () => {
		window.location.href = `/user/${user.ID}`;
	};

	const [id, setId] = useState(null);
	const close = () => {
		if (id) {
			setId(null);
		}
	};

	return (
		<>
			<PostSwiperWrapper onClick={close}>
				<div className="post-wrapper">
					<Swiper
						spaceBetween={10}
						slidesPerView={1.15}
						// loop={true}
						onSlideChange={() => console.log('slide change')}
						onSwiper={(swiper) => console.log(swiper)}
						breakpoints={{
							1024: {
								slidesPerView: 2.74,
								spaceBetween: 25,
							},
						}}
					>
						{posts.map((post, idx) => (
							<SwiperSlide
								className="interest-card"
								style={{
									position: 'relative',
									backgroundImage: `url(${post.post_image})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									width: 274,
									height: 274,
									borderRadius: 20,
								}}
							>
								<div
									className="tns"
									onClick={() => window.open(`/story/${post.ID}`)}
								></div>
								{/* <ul
                  // onMouseOver={() => setId(post.ID)}
                  // onMouseOut={() => setId(null)}
                  className="more-btn"
                >
                  <li></li>
                  <li></li>
                  <li></li>
                </ul> */}
								<div className="crystal-box">
									<ul className="btn-list">
										<li className="crystal-title">
											<button className="crystal-btn" onClick={crystalToggle2}>
												수정
											</button>
										</li>
										<li className="delete-title">
											<button className="crystal-btn">삭제</button>
										</li>
									</ul>
								</div>
								<div className="post-text-card">
									<div className="top-btn">
										<p className="categories">{post.cate_name}</p>
									</div>
									{/* {id === post.ID ? ( */}
									{/* ) : null} */}
									<p
										className="post-card-title"
										dangerouslySetInnerHTML={{ __html: post.post_title }}
									></p>{' '}
									<p className="date">{post.post_date}</p>
								</div>
								<div className="tag-box">
									<ul className="tag-list">
										{post?.post_tags
											? post?.post_tags?.map((item, key) => (
													<li>#{item.name}</li>
											  ))
											: ''}{' '}
										{/* <li>#Consult</li>
                    <li className="ing-tag">ing</li> */}
									</ul>
									<img
										src={`${post.post_author_img}`}
										alt="profile thumbnail"
									/>
								</div>
							</SwiperSlide>
						))}

						<div className="post-bg"></div>
					</Swiper>
				</div>
				{id ? <div onClick={close} className="dim" /> : null}
			</PostSwiperWrapper>
			{show7 ? <More moreToggle={moreToggle} /> : ''}
			{show10 ? <Crystal2 crystalToggle2={crystalToggle2} /> : ''}
		</>
	);
};

export default ReactLikeSwiper;
