export default function UnderArrow() {
  return (
    <>
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 16.7501L1 16.7501L1 0.750102M15.6395 2.56155L1.201 17"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    </>
  );
}
