export default function MohubArrow() {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1543 8.6084L5.20353 4.56184L1.1543 0.515278"
        stroke="black"
        stroke-width="1.21212"
      />
    </svg>
  );
}
