import MainContainer from '../../../components/MainContainer';
import Header from '../../../components/daitsoHeader';
import AddPen from '../../../vector/Addpen';
import CrystalIcon from '../../../vector/CrystalIcon';
import HomeIcon from '../../../vector/IconHome';
import PenIcon from '../../../vector/IconPen';
import MenuBook from '../../../vector/MenuBook';
import SauareIcon from '../../../vector/SauareIcon';
import UnderArrow from '../../../vector/UnderArrow';
import { Wrapper } from './styled';
import QuestionIcon from '../../../vector/QuestionMark';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { CardSlide } from '../../Swiper/styled';
import ReactSwiper from '../../Swiper';
import TimeIcon from '../../../vector/TimeIcon';
import { PostSwiperWrapper } from '../../Swiper/PostSwiper/styled';
import ReactPostSwiper from '../../Swiper/PostSwiper';
import ReactLikeSwiper from '../../Swiper/LikeSwiper';
import NoonIcon from '../../../vector/NoonIcon';
import NoneIcon from '../../../vector/NoonIcon';
import MohubArrow from '../../../vector/MohubArrow';
import { useEffect, useState } from 'react';
import Modal1 from '../../Dashboard/mentor/modal/modal1';
import Modal3 from '../../Dashboard/mentor/modal/modal3';
import MentoringOn from '../../Dashboard/mentor/modal/mentoring-board';
import Mypageactivity from '../../Dashboard/mentor/modal/mypage-activity';
import Activity from '../../Dashboard/mentor/modal/Activity';
import Crystal from '../../Dashboard/mentor/modal/crystal';
import Viewall from '../../Dashboard/mentor/modal/viewall';
import LiveModal from '../../Dashboard/mentor/modal/livemodal';
import LiveSwiper from '../../Swiper/LiveSwiper';
import { useSelector } from 'react-redux';
import { GetGroups, GetMenteeInfo, GetUserPosts } from '../../../api/story';
import HubModal from '../../../components/Popup/CommunicationHub';
import HubClose from '../../../vector/HubClose';
import { useHistory } from 'react-router-dom';
import IFeame from '../../Dashboard/iFrame';
import { GetAllUsers } from '../../../api/user';
import Currentcon from '../../../vector/Current';
import ArrowRightTop from '../../../vector/ArrowRightTop';
import ManualArrowIcon from '../../../vector/ManualArrow';
import ManualIcon from '../../../vector/Manual';
import MoManual from '../../../vector/MoManual';
import BigArrow from '../../../vector/BigArrow';
import Mokbdsarrow from '../../../vector/MoKbsdArrow';
import {
	GetActivity,
	GetMentees,
	togglePaddlet,
} from '../../../api/mentorings';
import {
	Link,
	useLocation,
	useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import Card from '../../Dashboard/mentor/modal/card';
import Qrcode from '../../Dashboard/mentor/modal/qrcode';
import SwiperCore, { Navigation } from 'swiper';

export default function Mypage2() {
	SwiperCore.use([Navigation]);

	const [Feame, setFeame] = useState(false);
	const feameToggle = () => {
		// if (user?.ID == 1) {
		// 	setFeame((p) => !p);
		// } else {
		// 	alert('테스트 중입니다.');
		// }
		setFeame((p) => !p);
	};

	const [isPadlet, setIsPadlet] = useState(0);

	const [show, setShow] = useState(false);
	const modalToggle = () => {
		setShow((p) => !p);
	};
	const [show3, setShow3] = useState(false);
	const modalToggle3 = () => {
		setShow3((p) => !p);
	};
	const [show4, setShow4] = useState(false);
	const mentoringon = () => {
		setShow4((p) => !p);
	};
	const [show5, setShow5] = useState(false);
	const mypageacticity = () => {
		setShow5((p) => !p);
	};
	const [show6, setShow6] = useState(false);
	const activity = () => {
		setShow6((p) => !p);
	};
	const [show8, setShow8] = useState(false);
	const crystalToggle = () => {
		setShow8((p) => !p);
	};
	const [show9, setShow9] = useState(false);
	const viewallToggle = () => {
		setShow9((p) => !p);
	};
	const [show10, setShow10] = useState(false);
	const eventToggle = () => {
		setShow10((p) => !p);
	};

	const [show11, setShow11] = useState(false);
	const cardToggle = () => {
		setShow11((p) => !p);
	};

	const [hubModal, setHubModal] = useState(false);
	const [menteeInfo, setMenteeInfo] = useState();
	const [loading, setLoading] = useState(true);
	const [showHub, setShowHub] = useState(false);
	const [posts, setPosts] = useState([]);
	const user = useSelector((state) => state.auth.user_data);
	const history = useHistory();
	const [posts1, setPosts1] = useState([]);
	const [posts2, setPosts2] = useState([]);
	const [posts3, setPosts3] = useState([]);

	useEffect(() => {
		GetUserPosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			post_author: user.ID,
			paged: 1,
		})
			.then((res) => {
				setPosts(res.data.body.posts);
			})
			.catch((err) => console.log('포스트 정보 오류', err));
	}, [user.ID]);
	useEffect(() => {
		GetMenteeInfo().then((res) => {
			setMenteeInfo(res.data.body?.result);
		});
	}, []);

	useEffect(() => {
		user && setLoading(false);
		setIsPadlet(user?.isPadlet);
	}, [user]);

	useEffect(() => {
		if (Feame) {
			document.getElementsByTagName('body')[0].classList.add('hidden');
		} else {
			document.getElementsByTagName('body')[0].classList.remove('hidden');
		}
	}, [Feame]);

	const [users, setUsers] = useState([]);

	const [groups, setGroups] = useState([]);

	useEffect(() => {
		GetAllUsers()
			.then((res) => {
				console.log(res);
				setUsers(res?.data.body.users);
			})
			.catch((err) => {
				console.log(err);
			});

		GetGroups({})
			.then((res) => {
				setGroups(res.data.body.posts);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [mentees, setMentees] = useState([]);
	const [p, setP] = useState(true);
	const [mentoringNum, setMentoringNum] = useState(0);

	// const params = useParams();

	useEffect(() => {
		GetMentees({})
			.then((res) => {
				console.log(res);
				setMentees(res?.data?.body?.mentees);
				setMentoringNum(res?.data?.body?.mentoringCountMonth);
			})
			.then((err) => {
				console.log(err);
			});
	}, [p]);

	const [activities, setActivities] = useState([]);
	const [p2, setP2] = useState(true);

	useEffect(() => {
		GetActivity({})
			.then((res) => {
				console.log(res);
				if (res?.data?.code == 200) {
					setActivities(res?.data?.body?.posts);
				} else {
					setActivities(null);
				}
			})
			.then((err) => {
				console.log(err);
			});
	}, [p2]);

	const isContent = false;

	const togglePd = () => {
		togglePaddlet()
			.then((res) => {
				console.log(res);
				setIsPadlet(res.data.body.result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const openPadlet = () => {
		if (isPadlet == 1) {
			togglePd();
		} else {
			mentoringon();
		}
	};

	let { search } = useLocation();

	const query = new URLSearchParams(search);
	const paramField = query.get('test');
	// const paramValue = query.get('value');

	console.log(paramField, 333);
	return (
		<>
			<MainContainer>
				<Wrapper>
					{Feame ? <div onClick={feameToggle} className="overlay"></div> : null}
					<div className="my-wrapper">
						<div className="top-wrap">
							<h1 className="my-title">My Page</h1>
							<button onClick={() => history.push('/feed')}>
								<i className="icon-home">
									<HomeIcon />
								</i>
								다it소 메인으로 가기
							</button>
						</div>
						<div className="mo-none-box">
							<div className="profile-wrap">
								<div className="left-box">
									<div className="profile-box">
										<div className="pro-wrap">
											<img src={user.user_avatar_url} alt="" />
											{/* <div
                        className="avatar"
                        style={{
                          backgroundImage: urluser.user_avatar_url
                            ? user.user_avatar_url
                            : 'https://secure.gravatar.com/avatar/?s=96&d=mm&r=g',
                        }}
                        alt=""
                      /> */}
											<div className="profile-text-box">
												<p className="profile-department">
													{user.user_department}
												</p>
												<h1 className="profile-hello">
													<span>{user.user_name}</span>
												</h1>
												<span className="user-description">
													{user?.description}
												</span>
											</div>
										</div>
										<div className="my-wrap">
											<div className="my-info-box">
												<div className="email">
													<p className="list-title">Email</p>
													<a href={`mailto:${user.user_email}`}>
														<p className="list-text mail-text">
															{user.user_email}
														</p>
													</a>
												</div>
												<div className="phone">
													<p className="list-title">Phone</p>
													<a
														href={
															user.user_phone ? `tel:${user.user_phone}` : null
														}
													>
														<p className="list-text">
															{user.user_phone
																? user.user_phone
																: '등록된 번호가 없습니다.'}
														</p>
													</a>
												</div>
											</div>
											<div className="btn-wrap">
												<button
													onClick={() =>
														window.open(
															`${
																process.env.REACT_APP_BACK_URI +
																'/wp-admin/profile.php'
															}`
														)
													}
												>
													<i className="icon-crtstal">
														<CrystalIcon />
													</i>
													내 정보 수정
												</button>
												<button
													className="black-btn"
													onClick={() =>
														window.open(
															'https://wp.daitso.kbds.co.kr/wp-admin/post-new.php'
														)
													}
												>
													<i className="icon-pen">
														<PenIcon />
													</i>
													글 작성하기
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="in">
									<div className="esg">
										<div className="esg-left">
											<div className="fx">
												<img src="/esg-icon.png" />
												<p>ESG</p>
											</div>
											<div className="percent">{user?.successNum} / 52</div>
										</div>
										{0 <= user?.esgGuage && user?.esgGuage <= 25 ? (
											<img className="esg-img" src="/earth.svg" />
										) : null}
										{26 <= user?.esgGuage && user?.esgGuage <= 50 ? (
											<img className="esg-img" src="/earth02.svg" />
										) : null}
										{51 <= user?.esgGuage && user?.esgGuage <= 75 ? (
											<img className="esg-img" src="/earth03.svg" />
										) : null}
										{76 <= user?.esgGuage && user?.esgGuage < 100 ? (
											<img className="esg-img" src="/earth04.svg" />
										) : null}
										{100 <= user?.esgGuage ? (
											<img className="esg-img" src="/earth05.svg" />
										) : null}
									</div>

									<div className={show ? 'right-box t show' : 'right-box t'}>
										<div
											className="right-text-box"
											onClick={() => setShowHub((p) => !p)}
										>
											<h1 className="right-title">
												<div className="flex">
													<div>
														<SauareIcon />
														HUB
													</div>
													<div className="icon-under-arrow">
														<img src="/arvector.png" />
													</div>
												</div>
												{/* {showHub ? (
													<div className="close-btn">
														<HubClose onClick={() => setShow((p) => !p)} />
													</div>
												) : null} */}
											</h1>
											{/* <div className="icon-box">
											<i className="icon-under-arrow">
												<UnderArrow />
											</i>
											<i className="icon-sauare">
												<SauareIcon />
											</i>
										</div> */}
										</div>
										{showHub ? <HubModal /> : null}
									</div>
								</div>
								{/* <div className={show ? 'right-box show' : 'right-box'}>
									<div
										className="right-text-box"
										onClick={() => setShowHub((p) => !p)}
									>
										<h1 className="right-title">
											커뮤니케이션 HUB
											{showHub ? (
												<div className="close-btn">
													<HubClose onClick={() => setShow((p) => !p)} />
												</div>
											) : null}
										</h1>
										<p className="right-text">KBDS의 채널들을 소개합니다.</p>
										<div className="icon-box">
											<i className="icon-under-arrow">
												<UnderArrow />
											</i>
											<i className="icon-sauare">
												<SauareIcon />
											</i>
										</div>
									</div>
									{showHub ? <HubModal /> : null}
								</div> */}
							</div>
							<div className="under-box">
								<ul className="point-list">
									<li>
										<p className="point-title">누적포인트</p>
										<p className="point-text">
											{user ? user.user_point_total : '0'}P
										</p>
									</li>
									<li>
										<p className="point-title">현재 포인트</p>
										<p className="point-text">{user ? user.user_point : ''}P</p>
									</li>
									<li>
										<p className="point-title">봉사시간</p>
										<p className="point-text">
											{user ? user.volunteerTime : '0'}시간
										</p>
									</li>
									<li>
										<p className="point-title">작성 포스트</p>
										<p className="point-text">{posts ? posts.length : '0'}개</p>
									</li>
									<li>
										<p className="point-title">관심 포스트</p>
										<p className="point-text">{user?.likeCount || 0}개</p>
									</li>
								</ul>
								<div
									className="faq-box"
									onClick={() =>
										window.open(
											'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
										)
									}
								>
									<div className="fap-icon-box">
										<i className="menubook-icon">
											<MenuBook />
										</i>
										<p className="faq-title">FAQ</p>
									</div>
									<i className="big-arrow">
										<BigArrow />
									</i>
								</div>
							</div>
						</div>
						{/* 모바일 */}
						<div className="mo-block-wrap">
							<div className="mo-pro-box">
								<div className="pro-wrap">
									<div className="profile-text-box">
										<p className="profile-department">{user.user_department}</p>
										<h1 className="profile-hello">
											<span>{user.user_name}</span>
										</h1>
									</div>
									<div className="right-icon">
										<div className="crtstal-btn">
											<button
												onClick={() =>
													window.open(
														`${
															process.env.REACT_APP_BACK_URI +
															'/wp-admin/profile.php'
														}`
													)
												}
											>
												<i className="icon-crtstal">
													<CrystalIcon />
												</i>
											</button>
										</div>
										<div className="business-card">
											<button onClick={() => history.push('/business/card')}>
												{/* <button onClick={cardToggle}> */}
												{/* {paramField ? (
													<img src="/icon-card.svg" alt="" />
												) : null} */}
												<img src="/icon-card.svg" alt="" />
											</button>
										</div>
									</div>
								</div>
								<div className="my-info-box">
									<div className="email">
										<p className="list-title">Email</p>
										<a href={`mailto:${user.user_email}`}>
											<p className="list-text mail-text">{user.user_email}</p>
										</a>
									</div>
									<div className="phone">
										<p className="list-title">Phone</p>
										<a href={user.user_phone ? `tel:${user.user_phone}` : null}>
											<p className="list-text">
												{user.user_phone
													? user.user_phone
													: '등록된 번호가 없습니다.'}
											</p>
										</a>
									</div>
								</div>
							</div>
							<div className="mo-under-box">
								<div className="under-wrap">
									<ul className="point-list">
										<li>
											<p className="point-title">포인트</p>
											<p className="point-text">
												{user ? user.user_point : '0'}P
											</p>
											<p className="under-point">
												(누적: {user ? user.user_point_total : '0'}P)
											</p>
										</li>
										{/* <li>
                      <p className="point-title">현재 포인트</p>
                      <p className="point-text">
                        {user ? user.user_point : ''}P
                      </p>
                    </li> */}
										<li>
											<p className="point-title">봉사시간</p>
											<p className="point-text">
												{user ? user.volunteerTime : '0'}시간
											</p>
										</li>
										<li>
											<p className="point-title">작성 포스트</p>
											<p className="point-text">
												{posts ? posts.length : '0'}개
											</p>
										</li>
										<li>
											<p className="point-title">관심 포스트</p>
											<p className="point-text">{user?.likeCount || 0}개</p>
										</li>
									</ul>

									<div className="right-box">
										<div className="esg">
											<div className="esg-left">
												<div className="fx">
													<img src="/esg-icon.png" />
													<p>ESG</p>
												</div>
												<div className="percent">
													{user?.successNum ? user?.successNum : 0} / 52
												</div>
											</div>
											{0 <= user?.esgGuage && user?.esgGuage <= 25 ? (
												<img className="esg-img" src="/earth.svg" />
											) : null}
											{26 <= user?.esgGuage && user?.esgGuage <= 50 ? (
												<img className="esg-img" src="/earth02.svg" />
											) : null}
											{51 <= user?.esgGuage && user?.esgGuage <= 75 ? (
												<img className="esg-img" src="/earth03.svg" />
											) : null}
											{76 <= user?.esgGuage ? (
												<img className="esg-img" src="/earth04.svg" />
											) : null}
											{100 <= user?.esgGuage ? (
												<img className="esg-img" src="/earth05.svg" />
											) : null}
										</div>
										<div
											className="right-text-box"
											onClick={() => setShowHub((p) => !p)}
										>
											{/* <h1 className="right-title">
												커뮤니케이션 HUB
												{showHub ? (
													<div className="close-btn">
														<HubClose onClick={() => setShow((p) => !p)} />
													</div>
												) : null}
											</h1>
											<p className="right-text">
												KBDS의 채널들을 소개합니다456.
											</p>

											<div className="icon-box">
												<i className="icon-under-arrow">
													<MohubArrow />
												</i>
												<i className="icon-sauare">
													<SauareIcon />
												</i>
											</div> */}

											<h1 className="right-title">
												<div className="flex">
													<div>
														<SauareIcon />
														HUB
													</div>
													<img className="" arvector src="/arvector.png" />
												</div>
												{/* {showHub ? (
													<div className="close-btn">
														<HubClose onClick={() => setShow((p) => !p)} />
													</div>
												) : null} */}
											</h1>
										</div>
										{showHub ? <HubModal /> : null}
										<div
											className="faq-box"
											onClick={() =>
												window.open(
													'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
												)
											}
										>
											<div className="fap-icon-box">
												<i className="menubook-icon">
													<MenuBook />
												</i>
												<p className="faq-title">FAQ</p>
											</div>
											<i className="big-arrow">
												<BigArrow />
											</i>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* 모바일 end */}
						{user?.role == 'mentor' ? (
							<div className="mentor-wrap">
								<div className="mentor-top">
									<div className="mentor-left">
										<h1 className="mentor-top-title">멘토링 현황</h1>
										<div className="mentor-border">멘토</div>
										<div className="add-box">
											<button className="add-btn" onClick={modalToggle3}>
												<i className="icon-add-pen">
													<AddPen />
												</i>
												새 멘티 추가
											</button>
										</div>
									</div>
									<div className="mentor-right">
										{/* <button onClick={() => window.open(`${user.menteePadlet}`)}>
											<i className="kbds-arrow">
												<ArrowRightTop />
											</i>
											KBDS STARTERS
										</button> */}
										<a href="https://padlet.com/kbdsstarters/kbds-wiki-b7w3ma8sfhcw44kf">
											<button className="kb-btn">
												<i className="kbds-arrow">
													<ArrowRightTop />
												</i>
												KBDS STARTERS
											</button>
										</a>
										<div className="onbtn-box">
											<button className="on-btn">멘토링보드 ON</button>
											<div className="switch-wrapper">
												<input
													type="checkbox"
													checked={isPadlet == 1 ? true : false}
													name=""
													id="switch"
												/>
												<label
													onClick={openPadlet}
													htmlFor="switch"
													className="switch_label"
												>
													<span className="onf_btn"></span>
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="second-box">
									<div className="ing-box">
										<p className="ing-title">
											당월 멘토링 : {mentoringNum}회 진행
										</p>
										<div className="ing-btn">
											<button onClick={mypageacticity}>활동 내역</button>
										</div>
									</div>
									<div className="add-box">
										<button onClick={modalToggle3}>
											<i className="icon-add-pen">
												<AddPen />
											</i>
											새 멘티 추가
										</button>
									</div>
								</div>
								<ReactSwiper user={user} mentees={mentees} />
							</div>
						) : null}

						{/* //-------------------------멘토링현황-------------------------// */}
						{user?.role == 'mentee' ? (
							<div className="mentee-wrap">
								<div className="mentee-left">
									<div className="mentor-top">
										<div className="mentor-left">
											<h1 className="mentor-top-title">멘토링 현황</h1>
											<div className="mentor-border">멘티</div>
										</div>
										<div className="mentor-right">
											<button>
												<i className="kbds-arrow">
													<ArrowRightTop />
												</i>
												패들릿으로 가기
											</button>
										</div>
									</div>
									<div className="current-wrap">
										{/* <div className="noti-txt">
										<img
											src="../images/icon-park-outline_attention.svg"
											alt=""
										/>
										<p onClick={() => console.log(menteeInfo)}>
											멘토링 활동이 저조합니다. 멘토링 활동은
											<span> 1개월 1회 이상++</span>
											권장합니다.
										</p>
									</div> */}
										{menteeInfo?.career == 'senior' ? (
											<ul className="current-list">
												{Array.apply(null, Array(5)).map((item, key) => (
													<li
														// style={{ zIndex: key }}
														className={key < menteeInfo?.stamp ? 'on' : ''}
													>
														<i className="icon-current">
															<Currentcon />
														</i>
														<p className="round-text">{key + 1}회차</p>
														{key < menteeInfo?.stamp ? (
															<div className="round-box1 complete-box">
																<p className="schedule-text complete-text">
																	complete
																</p>
															</div>
														) : (
															<div className="round-box1">
																<p className="schedule-text">예정</p>
															</div>
														)}
													</li>
												))}
												{/* <li className="on">
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">2회차</p>
													<div className="round-box1 complete-box">
														<p className="schedule-text complete-text">
															complete
														</p>
													</div>
												</li>
												<li>
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">3회차</p>
													<div className="round-box1">
														<p className="schedule-text">예정</p>
													</div>
												</li>
												<li className="">
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">4회차</p>
													<div className="round-box1">
														<p className="schedule-text">예정</p>
													</div>
												</li>
												<li className="">
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">5회차</p>
													<div className="round-box1">
														<p className="schedule-text">예정</p>
													</div>
												</li> */}
											</ul>
										) : null}
										{menteeInfo?.career == 'junior' ? (
											<ul className="current-list-three">
												{Array.apply(null, Array(5)).map((item, key) => (
													<li
														// style={{ zIndex: key }}
														className={key < menteeInfo?.stamp ? 'on' : ''}
													>
														<i className="icon-current">
															<Currentcon />
														</i>
														<p className="round-text">{key + 1}회차</p>
														{key < menteeInfo?.stamp ? (
															<div className="round-box1 complete-box">
																<p className="schedule-text complete-text">
																	complete
																</p>
															</div>
														) : (
															<div className="round-box1">
																<p className="schedule-text">예정</p>
															</div>
														)}
													</li>
												))}
												{/* <li className="on">
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">1회차</p>
													<div className="round-box1 complete-box">
														<p className="schedule-text complete-text">
															complete
														</p>
													</div>
												</li>
												<li className="on">
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">2회차</p>
													<div className="round-box1 complete-box">
														<p className="schedule-text complete-text">
															complete
														</p>
													</div>
												</li>
												<li>
													<i className="icon-current">
														<Currentcon />
													</i>
													<p className="round-text">3회차</p>
													<div className="round-box1">
														<p className="schedule-text">예정</p>
													</div>
												</li> */}
											</ul>
										) : null}
									</div>
								</div>
								<div className="mentee-right">
									<h1 className="mentee-title">신규입사자 매뉴얼</h1>
									<p className="mentee-text">
										KBDS의 다양한 채널들을 소개합니다.
									</p>

									<div className="manual-icon-box">
										<div className="manual-arrow-box">
											<i className="manual-arrow">
												<ManualArrowIcon />
											</i>
										</div>
										<i className="manual-icon">
											<ManualIcon />
										</i>
									</div>
								</div>
							</div>
						) : null}
						{/* //------------------------멘토링현황end-----------------------// */}

						{/* //-----------------------멘토링현황모바일-----------------------// */}
						{user?.role == 'mentee' ? (
							<div className="mo-mentee-wrap">
								<div className="mentee-top">
									<div className="manual-box">
										<i className="mo-manual-icon">
											<MoManual />
										</i>
										<h1 className="mentee-title">신규입사자 매뉴얼</h1>
									</div>
									<i className="big-arrow">
										<BigArrow />
									</i>
								</div>
								<div className="mentee-under">
									<div className="mentor-top">
										<div className="mentor-left">
											<h1 className="mentor-top-title">멘토링 현황</h1>
											<div className="mentor-border">멘티</div>
										</div>
										<div className="mentor-right">
											{/* <button onClick={() => window.open(menteeInfo?.career)}>
												<i className="kbds-arrow">
													<Mokbdsarrow />
												</i>
												패들릿으로 가기
											</button> */}
											<button>
												<i className="kbds-arrow">
													<Mokbdsarrow />
												</i>
												패들릿으로 가기
											</button>
										</div>
									</div>
									<div className="current-wrap">
										<div className="noti-txt-box">
											{/* <div className="noti-txt">
												<img
													src="../images/icon-park-outline_attention.svg"
													alt=""
												/>
												<p>
													멘토링 활동이 저조합니다.
													<br /> 멘토링 활동은
													<span> 1개월 1회 이상</span>권장합니다.
												</p>
											</div> */}
										</div>
										{/* <ul className="current-list">
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">1회차</p>
											<p className="date">
												2022.01.14 <span>(수)</span>
											</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li className="on">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">2회차</p>
											<p className="date">
												2022.03.14 <span>(수)</span>
											</p>
											<div className="round-box1 complete-box">
												<p className="schedule-text complete-text">complete</p>
											</div>
										</li>
										<li>
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">3회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">4회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
										<li className="">
											<i className="icon-current">
												<Currentcon />
											</i>
											<p className="round-text">5회차</p>
											<div className="round-box1">
												<p className="schedule-text">예정</p>
											</div>
										</li>
									</ul> */}
										<ul className="current-list-three">
											<li className="on">
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">1회차</p>
												<p className="date">
													2022.01.14 <span>(수)</span>
												</p>
												<div className="round-box1 complete-box">
													<p className="schedule-text complete-text">
														complete
													</p>
												</div>
											</li>
											<li className="on">
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">2회차</p>
												<p className="date">
													2022.03.14 <span>(수)</span>
												</p>
												<div className="round-box1 complete-box">
													<p className="schedule-text complete-text">
														complete
													</p>
												</div>
											</li>
											<li>
												<i className="icon-current">
													<Currentcon />
												</i>
												<p className="round-text">3회차</p>
												<div className="round-box1">
													<p className="schedule-text">예정</p>
												</div>
											</li>
										</ul>
									</div>
									<div className="more-box">
										<button>모두 보기</button>
									</div>
									<div className="gradient-box">
										<div className="bg-l"></div>
										<div className="bg-r"></div>
									</div>
								</div>
							</div>
						) : null}

						{/* //----------------------멘토링현황모바일end---------------------// */}
						<div className="live-wrap">
							<div className="live-top-menu">
								<h2 className="live-title">KBDS LIVE</h2>
								<div className="registr-btn-wrap">
									<button className="registr-btn" onClick={eventToggle}>
										<i className="icon-pen">
											<PenIcon />
										</i>
										간편 작성
									</button>
								</div>
							</div>
							<LiveSwiper />
							<div className="post-line"></div>
							<button
								className="all-btn"
								onClick={() => history.push(`/user/${user.ID}?tab=3`)}
							>
								모두 보기
							</button>
						</div>
						<div className="post-title-wrap">
							<div className="post-title-box">
								<h2 className="post-title">작성 포스트</h2>
								{/* <button className="post-btn" onClick={crystalToggle}>
                  <i className="icon-add-pen">
                    <AddPen />
                  </i>
                  새 포스트 작성
                </button> */}
							</div>
							<div className="comment-tit-box">
								<h2 className="post-title mo-post-title">내 댓글</h2>
							</div>
						</div>
						<div className="post-wrap">
							<div className="post-box">
								<div className="writing-wrap">
									{posts1?.length ? (
										<ReactPostSwiper setPosts1={setPosts1} />
									) : (
										<div className="none-box">
											<div style={{ visibility: 'hidden' }}>
												<ReactPostSwiper setPosts1={setPosts1} />
											</div>
											<i className="icon-none">
												<NoneIcon />
											</i>
											<p className="none-text">
												아직 작성된 포스트가 없습니다.
											</p>
										</div>
									)}
									<div className="post-line"></div>
									<button
										className="all-btn"
										onClick={() => history.push(`/user/${user.ID}?tab=2`)}
									>
										모두 보기
									</button>
								</div>
								<div className="interest-wrap">
									<h1 className="interest-title">관심 포스트</h1>
									<div className="interest-bg">
										{posts2?.length ? (
											<ReactLikeSwiper setPosts2={setPosts2} />
										) : (
											<div className="none-box">
												<div style={{ visibility: 'hidden' }}>
													<ReactLikeSwiper setPosts2={setPosts2} />
												</div>
												<i className="icon-none">
													<NoneIcon />
												</i>
												<p className="none-text">
													아직 관심 포스트가 없습니다.
												</p>
											</div>
										)}
										<div className="post-line"></div>
										<button
											className="all-btn"
											onClick={() => history.push(`/user/${user.ID}?tab=4`)}
										>
											모두 보기
										</button>
									</div>
								</div>
							</div>
							<div className="mo-comment-box">
								<h1 className="mo-comment-title">내 댓글</h1>
							</div>
							<div className="comment-wrap">
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								{/* <ul className="comment-list">
                  <li className="comment-box">
                    <p className="comment">참 좋은 내용이네요...</p>
                    <p className="date">
                      <i className="icon-time">
                        <TimeIcon />
                      </i>
                      2022-12-12 18:00
                    </p>
                  </li>
                  <li className="comment-user">
                    <img src="user-img.png" alt="" />
                    <p className="opinion">
                      내 추억의 소설이 영화화 되었다...[워드아웃...
                    </p>
                  </li>
                  <li className="comment-box">
                    <p className="comment">
                      저는 화면 개발 쪽 맡고 있고요. it 언어를 쓰는 걸 좋아해서
                      저는 뷰js를 쓰고 있고 일부 스프링 부트랑 타임 리프를
                      사용하고 있습니다. 화면을 개발해서 백엔드와 협업해서
                      작업을 하고 있습니다.
                    </p>
                    <p className="date">
                      <i className="icon-time">
                        <TimeIcon />
                      </i>
                      2022-12-12 18:00
                    </p>
                  </li>
                  <li className="comment-user">
                    <img src="user-img.png" alt="" />
                    <p className="opinion">
                      내 추억의 소설이 영화화 되었다...[워드아웃...
                    </p>
                  </li>
                  <li className="comment-box">
                    <p className="comment">참 좋은 내용이네요...</p>
                    <p className="date">
                      <i className="icon-time">
                        <TimeIcon />
                      </i>
                      2022-12-12 18:00
                    </p>
                  </li>
                  <li className="comment-user">
                    <img src="user-img.png" alt="" />
                    <p className="opinion">
                      내 추억의 소설이 영화화 되었다...[워드아웃...
                    </p>
                  </li>
                  <li className="comment-box">
                    <p className="comment">
                      저는 화면 개발 쪽 맡고 있고요. it 언어를 쓰는 걸 좋아해서
                      저는 뷰js를 쓰고 있고 일부 스프링 부트랑 타임 리프를
                      사용하고 있습니다. 화면을 개발해서 백엔드와 협업해서
                      작업을 하고 있습니다.
                    </p>
                    <p className="date">
                      <i className="icon-time">
                        <TimeIcon />
                      </i>
                      2022-12-12 18:00
                    </p>
                  </li>
                  <li className="comment-user">
                    <img src="user-img.png" alt="" />
                    <p className="opinion">
                      내 추억의 소설이 영화화 되었다...[워드아웃...
                    </p>
                  </li>
                </ul> */}
								{/* <div className="comment-bg-box">
                  <div className="comment-bg">
                    <button>모두 보기</button>
                  </div>
                </div> */}
								<div className="mo-comment-bg-box">
									<div className="comment-bg"></div>
									<button>모두 보기</button>
								</div>
							</div>
						</div>
						<div className="club-wrap">
							<a
								className="club-box blog"
								href="https://blog.kbds.co.kr/"
								target="_blank"
							>
								<h1 className="club-title">
									공식블로그 바로가기
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="19"
										height="19"
										viewBox="0 0 19 19"
										fill="none"
									>
										<path
											d="M4.03516 2L16.9997 2L16.9997 14.9645"
											stroke="white"
											stroke-width="2.5"
											stroke-linecap="round"
										/>
										<path
											d="M16.4106 2.58926L1.99942 17.0005"
											stroke="white"
											stroke-width="2.5"
											stroke-linecap="round"
										/>
									</svg>
								</h1>
								{/* <div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button> */}
							</a>
							<div className="club-box group cop">
								<h1 className="club-title">KBDS YOUTUBE</h1>
								<div className="group-wrapper">
									<Swiper
										spaceBetween={40}
										slidesPerView={1}
										onSlideChange={() => console.log('slide change')}
										onSwiper={(swiper) => console.log(swiper)}
										speed={800}
										navigation={true}
										loop={true}
										/* 전체적인 슬라이드의 오른쪽에 20px 공백을 준다. */
										// 	1024: {
										// 		slidesPerView: 4.15,
										// 		spaceBetween: 25,
										// 	},
										// }}
									>
										<SwiperSlide className="post-card">
											<div
												className="group-card cop"
												style={{
													backgroundImage: `url(/images/cop-daitso.png)`,
												}}
												onClick={() =>
													window.open(
														'https://www.youtube.com/channel/UCFsYPVbeWWdg6zM7mDHpUJQ'
													)
												}
											>
												<div className="ir">
													<p>Youtube</p>
													<div className="title">KB다IT소 바로가기</div>
												</div>
											</div>
										</SwiperSlide>
										<SwiperSlide className="post-card">
											<div
												className="group-card cop"
												style={{
													backgroundImage: `url(/images/cop-thoitso.png)`,
												}}
												onClick={() =>
													window.open('https://www.youtube.com/@kbdsin')
												}
											>
												<div className="ir">
													<p>Youtube</p>
													<div className="title">또IT소 바로가기</div>
												</div>
											</div>
										</SwiperSlide>
									</Swiper>
								</div>
							</div>
							<div className="club-box group">
								<h1 className="club-title">
									소모임
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="4"
											height="4"
											viewBox="0 0 4 4"
											fill="none"
										>
											<circle cx="2" cy="2" r="2" fill="#E9E9E9" />
										</svg>
										{groups?.length}
									</span>
								</h1>
								<div className="group-wrapper">
									<Swiper
										spaceBetween={40}
										slidesPerView={1}
										onSlideChange={() => console.log('slide change')}
										onSwiper={(swiper) => console.log(swiper)}
										speed={800}
										navigation={true}
										loop={true}
										/* 전체적인 슬라이드의 오른쪽에 20px 공백을 준다. */
										// 	1024: {
										// 		slidesPerView: 4.15,
										// 		spaceBetween: 25,
										// 	},
										// }}
									>
										{groups.map((item, idx) => (
											<SwiperSlide className="post-card">
												<div
													className="group-card"
													style={{
														backgroundImage: `url(${item.thumbnail})`,
													}}
													onClick={() =>
														(window.location.href = `/group/${item?.ID}`)
													}
												>
													<div className="ir">
														<p>소모임</p>
														<div className="title">{item.postTitle}</div>
													</div>
													<div className="flex">
														<div
															className="avatar"
															style={{
																backgroundImage: `url(${item?.small_group_leader?.avatar})`,
															}}
														/>
														<span>
															<b>+{item?.member_count}명</b> 참여중
														</span>
													</div>
												</div>
											</SwiperSlide>
										))}

										<div className="post-bg"></div>
									</Swiper>
								</div>
								{/* <div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button> */}
							</div>
						</div>
						{/* <div className="none-club-wrap">
              <div className="none-club-box">
                <div className="no-open">
                  <i className="icon-none">
                    <NoneIcon />
                  </i>
                  <p className="none-text">오픈준비중입니다.</p>
                </div>
              </div>
            </div> */}
						<div className="esg-btn-wrap">
							<div className="h-text">ESG 챌린지</div>
							{user?.ID ? (
								<>
									<div className="esg-btn pc" onClick={feameToggle}>
										<img src="/icon-esg.svg" alt="" />
									</div>
									<div
										className="esg-btn mo"
										onClick={() =>
											(window.location.href = `https://esg.kbds.co.kr/?userToken=${
												localStorage.getItem('user_token')
													? localStorage.getItem('user_token')
													: ''
											}`)
										}
									>
										<img src="/icon-esg.svg" alt="" />
									</div>
								</>
							) : null}
						</div>
					</div>
				</Wrapper>
				<div onClick={() => console.log(mentees)}></div>
			</MainContainer>
			{show ? <Modal1 modalToggle={modalToggle} /> : ''}
			{show3 ? (
				<Modal3
					modalToggle3={modalToggle3}
					user={user}
					users={users}
					setP={setP}
				/>
			) : (
				''
			)}
			{show4 ? (
				<MentoringOn
					user={user}
					mentoringon={mentoringon}
					togglePd={togglePd}
					// setTmp={setTmp}
				/>
			) : (
				''
			)}
			{show5 ? (
				<Mypageactivity
					activity={activity}
					mypageacticity={mypageacticity}
					activities={activities}
					setP2={setP2}
				/>
			) : (
				''
			)}
			{show6 ? (
				<Activity
					user={user}
					activity={activity}
					mentees={mentees}
					setP2={setP2}
				/>
			) : (
				''
			)}
			{show8 ? <Crystal crystalToggle={crystalToggle} /> : ''}
			{show9 ? <Viewall viewallToggle={viewallToggle} /> : ''}
			{show10 ? <LiveModal eventToggle={eventToggle} /> : ''}
			{show11 ? <Card user={user} cardToggle={cardToggle} /> : ''}

			{/* {Feame ? <IFeame modalToggle={feameToggle} Feame={Feame} /> : ''} */}
			<IFeame modalToggle={feameToggle} Feame={Feame} />
		</>
	);
}
