import MainContainer from '../../../components/MainContainer';
import Header from '../../../components/daitsoHeader';
import AddPen from '../../../vector/Addpen';
import ArrowRightTop from '../../../vector/ArrowRightTop';
import BigArrow from '../../../vector/BigArrow';
import CrystalIcon from '../../../vector/CrystalIcon';
import HomeIcon from '../../../vector/IconHome';
import PenIcon from '../../../vector/IconPen';
import MenuBook from '../../../vector/MenuBook';
import SauareIcon from '../../../vector/SauareIcon';
import UnderArrow from '../../../vector/UnderArrow';
import { Wrapper } from './styled';
import QuestionIcon from '../../../vector/QuestionMark';
import { Swiper } from 'swiper/react';
import 'swiper/swiper.min.css';
import { CardSlide } from '../../Swiper/styled';
import ReactSwiper from '../../Swiper';
import TimeIcon from '../../../vector/TimeIcon';
import { PostSwiperWrapper } from '../../Swiper/PostSwiper/styled';
import ReactPostSwiper from '../../Swiper/PostSwiper';
import NoonIcon from '../../../vector/NoonIcon';
import NoneIcon from '../../../vector/NoonIcon';
import MohubArrow from '../../../vector/MohubArrow';
import { useState } from 'react';
import Modal1 from '../../Dashboard/mentor/modal/modal1';
import Modal3 from '../../Dashboard/mentor/modal/modal3';
import MentoringOn from '../../Dashboard/mentor/modal/mentoring-board';
import { useEffect } from 'react';
import { GetMenteeInfo, GetUserPosts } from '../../../api/story';
import HubClose from '../../../vector/HubClose';
import HubModal from '../../../components/Popup/CommunicationHub';

export default function Mypageactivation(user) {
	const [show, setShow] = useState(false);
	const modalToggle = () => {
		setShow((p) => !p);
	};
	const [show3, setShow3] = useState(false);
	const modalToggle3 = () => {
		setShow3((p) => !p);
	};
	const [show4, setShow4] = useState(false);
	const mentoringon = () => {
		setShow4((p) => !p);
	};

	const [posts, setPosts] = useState([]);
	const [menteeInfo, setMenteeInfo] = useState();
	const [showHub, setShowHub] = useState(false);

	useEffect(() => {
		GetUserPosts({
			order: 'DESC',
			orderby: 'ID',
			posts_per_page: '12',
			post_author: user.ID,
			paged: 1,
		})
			.then((res) => {
				setPosts(res.data.body.posts);
			})
			.catch((err) => console.log('포스트 정보 오류', err));
	}, [user.ID]);
	useEffect(() => {
		GetMenteeInfo().then((res) => {
			setMenteeInfo(res.data.body?.result);
		});
	}, []);

	return (
		<>
			<MainContainer>
				<Wrapper>
					<div className="my-wrapper">
						<div className="top-wrap">
							<h1 className="my-title">Profile</h1>
							<button>
								<i className="icon-home">
									<HomeIcon />
								</i>
								다it소 메인으로 가기
							</button>
						</div>
						<div className="mo-none-box">
							<div className="profile-wrap">
								<div className="left-box">
									<div className="profile-box">
										<div className="pro-wrap">
											<img src="profile-img.png" alt="" />
											<div className="profile-text-box">
												<p className="profile-department">경영기획부</p>
												<h1 className="profile-hello">
													<span>홍길동</span>님 안녕하세요!
												</h1>
											</div>
										</div>
										<div className="my-wrap">
											<div className="my-info-box">
												<div className="email">
													<p className="list-title">Email</p>
													<p className="list-text mail-text">
														jhsuk@easypress.me
													</p>
												</div>
												<div className="phone">
													<p className="list-title">Phone</p>
													<p className="list-text">010-1234-5678</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="right-box">
									<div className="right-text-box">
										<h1 className="right-title">커뮤니케이션 HUB</h1>
										<p className="right-text">KBDS의 채널들을 소개합니다.</p>
										<div className="icon-box">
											<i className="icon-under-arrow">
												<UnderArrow />
											</i>
											<i className="icon-sauare">
												<SauareIcon />
											</i>
										</div>
									</div>
								</div>
							</div>
							<div className="under-box">
								<ul className="point-list">
									<li>
										<p className="point-title">누적포인트</p>
										<p className="point-text">
											{user ? user.user_point_total : '0'}P
										</p>
									</li>
									<li>
										<p className="point-title">현재 포인트</p>
										<p className="point-text">{user ? user.user_point : ''}P</p>
									</li>
									<li>
										<p className="point-title">봉사시간</p>
										<p className="point-text">
											{user ? user.volunteerTime : '0'}시간
										</p>
									</li>
									<li>
										<p className="point-title">작성 포스트</p>
										<p className="point-text">{posts ? posts.length : '0'}개</p>
									</li>
									<li>
										<p className="point-title">관심 포스트</p>
										<p className="point-text">{user?.likeCount || 0}개</p>
									</li>
								</ul>
								<div
									className="faq-box"
									onClick={() =>
										window.open(
											'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
										)
									}
								>
									<div className="fap-icon-box">
										<i className="menubook-icon">
											<MenuBook />
										</i>
										<p className="faq-title">FAQ</p>
									</div>
									<i className="big-arrow">
										<BigArrow />
									</i>
								</div>
							</div>
						</div>
						{/* 모바일 */}
						<div className="mo-block-wrap">
							<div className="mo-pro-box">
								<div className="pro-wrap">
									<div className="profile-text-box">
										<p className="profile-department">{user.user_department}</p>
										<h1 className="profile-hello">
											<span>{user.user_name}</span>
										</h1>
									</div>
									<div className="crtstal-btn">
										<button
											onClick={() =>
												window.open(
													`${
														process.env.REACT_APP_BACK_URI +
														'/wp-admin/profile.php'
													}`
												)
											}
										>
											<i className="icon-crtstal">
												<CrystalIcon />
											</i>
										</button>
									</div>
								</div>
								<div className="my-info-box">
									<div className="email">
										<p className="list-title">Email</p>
										<a href={`mailto:${user.user_email}`}>
											<p className="list-text mail-text">{user.user_email}</p>
										</a>
									</div>
									<div className="phone">
										<p className="list-title">Phone</p>
										<a href={user.user_phone ? `tel:${user.user_phone}` : null}>
											<p className="list-text">
												{user.user_phone
													? user.user_phone
													: '등록된 번호가 없습니다.'}
											</p>
										</a>
									</div>
								</div>
							</div>
							<div className="mo-under-box">
								<div className="under-wrap">
									<ul className="point-list">
										<li>
											<p className="point-title">포인트</p>
											<p className="point-text">
												{user ? user.user_point : '0'}P
											</p>
											<p className="under-point">
												(누적: {user ? user.user_point_total : '0'}P)
											</p>
										</li>
										{/* <li>
                      <p className="point-title">현재 포인트</p>
                      <p className="point-text">
                        {user ? user.user_point : ''}P
                      </p>
                    </li> */}
										<li>
											<p className="point-title">봉사시간</p>
											<p className="point-text">
												{user ? user.volunteerTime : '0'}시간
											</p>
										</li>
										<li>
											<p className="point-title">작성 포스트</p>
											<p className="point-text">
												{posts ? posts.length : '0'}개
											</p>
										</li>
										<li>
											<p className="point-title">관심 포스트</p>
											<p className="point-text">{user?.likeCount || 0}개</p>
										</li>
									</ul>
									<div className="right-box">
										<div
											className="right-text-box"
											onClick={() => setShowHub((p) => !p)}
										>
											<h1 className="right-title">
												커뮤니케이션 HUB
												{showHub ? (
													<div className="close-btn">
														<HubClose onClick={() => setShow((p) => !p)} />
													</div>
												) : null}
											</h1>
											<p className="right-text">KBDS의 채널들을 소개합니다.</p>

											<div className="icon-box">
												<i className="icon-under-arrow">
													<MohubArrow />
												</i>
												<i className="icon-sauare">
													<SauareIcon />
												</i>
											</div>
										</div>
										{showHub ? <HubModal /> : null}
										<div
											className="faq-box"
											onClick={() =>
												window.open(
													'https://padlet.com/kbdsstarters/padlet-kcsy6xfx48npdldb'
												)
											}
										>
											<div className="fap-icon-box">
												<i className="menubook-icon">
													<MenuBook />
												</i>
												<p className="faq-title">FAQ</p>
											</div>
											<i className="big-arrow">
												<BigArrow />
											</i>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* 모바일 end */}
						<div className="mentor-wrap">
							<div className="mentor-top">
								<div className="mentor-left">
									<h1 className="mentor-top-title">멘토링 현황</h1>
									<div className="mentor-border">멘토</div>
								</div>
								<div className="mentor-right">
									<button onClick={() => window.open(`${user.menteePadlet}`)}>
										<i className="kbds-arrow">
											<ArrowRightTop />
										</i>
										KBDS STARTERS
									</button>
									<button className="on-btn">
										<i className="kbds-arrow">
											<ArrowRightTop />
										</i>
										멘토링보드 바로가기
									</button>
								</div>
							</div>
							{/* <div className="second-box">
                <div className="ing-box">
                  <p className="ing-title">당월 멘토링 : 00회 진행</p>
                  <div className="ing-btn">
                    <button onClick={modalToggle}>활동 내역</button>
                  </div>
                </div>
              </div> */}
							<ReactSwiper />
						</div>
						<div className="post-title-wrap">
							<div className="post-title-box">
								<h2 className="post-title">작성 포스트</h2>
							</div>
						</div>
						<div className="post-wrap">
							<div className="post-box">
								<div className="writing-wrap">
									<ReactPostSwiper />
									<div className="post-line"></div>
									<button>모두 보기</button>
								</div>
							</div>
						</div>
						<div className="club-wrap">
							<div className="club-box">
								<h1 className="club-title">동호회</h1>
								<div className="club-list">
									<div className="list-box">
										<img src="club1.png" alt="" />
										<p className="club-name">자전거 동호회</p>
									</div>
									<div className="list-box">
										<img src="club2.png" alt="" />
										<p className="club-name">테니스 동호회</p>
									</div>
									<div className="list-box">
										<img src="club3.png" alt="" />
										<p className="club-name">자전거 동호회</p>
									</div>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button>
							</div>
							<div className="club-box">
								<h1 className="club-title">COP</h1>
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button>
							</div>
							<div className="club-box">
								<h1 className="club-title">소모임</h1>
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
								<div className="allmore-line"></div>
								<button>모두 보기</button>
							</div>
						</div>
						<div className="none-club-wrap">
							<div className="none-club-box">
								<div className="no-open">
									<i className="icon-none">
										<NoneIcon />
									</i>
									<p className="none-text">오픈준비중입니다.</p>
								</div>
							</div>
						</div>
					</div>
				</Wrapper>
			</MainContainer>
			{show ? <Modal1 modalToggle={modalToggle} /> : ''}
			{show3 ? <Modal3 modalToggle3={modalToggle3} /> : ''}
			{show4 ? <MentoringOn mentoringon={mentoringon} /> : ''}
		</>
	);
}
