const SET_TOKEN = "auth/SET_TOKEN";
const SET_USER_DATA = "auth/SET_USER_DATA";

export const AuthSetToken = (token) => ({
    type:SET_TOKEN,
    payload:{
        token
    }
})
export const AuthSetUserData = (userdata) => ({
    type:SET_USER_DATA,
    payload:{
        userdata
    }
})


const initState = {
    user_token: '',
    user_data: '',
}

const user_token = localStorage.getItem('user_token');
if(user_token) {
    initState.user_token = user_token; 
}

const AuthReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                user_token: action.payload.token
            }
        case SET_USER_DATA:
            return {
                ...state,
                user_data: action.payload.userdata
            }

        default:
            return state;
    }
}
export default AuthReducer;