import { useState } from 'react';
import ModalClose from '../../../../../vector/ModalClose';
import ModalPrev from '../../../../../vector/ModalPrev';
import { Wrapper } from './styled';
import NoneIcon from '../../../../../vector/NoonIcon';
import CheckedIcon from '../../../../../vector/Checkedicon';
import ModalplusIcon from '../../../../../vector/Modalplusicon';
import ClaendarIcon from '../../../../../vector/ClaendarIcon';
import { AddActivity } from '../../../../../api/mentorings';

export default function Activity({ user, activity, mentees, setP2 }) {
	const [tempData, setTempData] = useState(true);

	const [idArray, setIdArray] = useState([]);

	const handleToggleItem = (newItem) => {
		const index = idArray.indexOf(newItem);

		if (index === -1) {
			// Item does not exist in the array, so add it.
			setIdArray([...idArray, newItem]);
		} else {
			// Item exists in the array, so remove it.
			const updatedItems = [...idArray];
			updatedItems.splice(index, 1);
			setIdArray(updatedItems);
		}

		// Clear the input field
	};

	const [date, setDate] = useState('');

	const DoAdd = () => {
		if (!date) {
			alert('활동일을 입력해주세요.');
			return;
		}

		if (!idArray?.length) {
			alert('참여 멘티를 선택해주세요.');
			return;
		}

		if (window.confirm('멘토링 활동을 등록하시겠습니까?')) {
			//
			AddActivity({
				mentoringId: user?.mentoringId,
				date: date,
				mentees: idArray,
			})
				.then((res) => {
					console.log(res);
					alert('등록이 완료되었습니다.');
					activity();
					setP2((p) => !p);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<Wrapper>
			<section className="modal-wrapper">
				<div className="overlay" onClick={activity}></div>
				<div className="modal-wrap">
					<i className="icon-close" onClick={activity}>
						<ModalClose />
					</i>
					<h2 className="modal-title">멘토링 활동 등록</h2>
					<div className="modal-box">
						<div className="modal-list-box">
							<p onClick={() => console.log(idArray)} className="moadl-text">
								활동일
							</p>
							<input
								onChange={(e) => setDate(e.target.value)}
								type="date"
								data-placeholder="날짜를 선택하세요"
							/>

							{/* <div className="date-box">
								<p className="date-text">날짜를 선택하세요</p>
								<i className="date-icon">
									<ClaendarIcon />
								</i>
							</div> */}
						</div>
						<div className="participation-mentee">
							<div className="mentee-text-box">
								<p className="moadl-text">참여 멘티</p>
							</div>
							<div className="mentee-box">
								{mentees?.length
									? mentees.map((item, key) => (
											<div
												onClick={() => handleToggleItem(item?.menteeId)}
												className="modal-list"
											>
												<div className="name-box">
													<i className="icon-modal-plus">
														{idArray?.includes(item?.menteeId) ? (
															<CheckedIcon />
														) : (
															<ModalplusIcon />
														)}
													</i>
													<p className="name">{item?.name}</p>
												</div>
												<p className="number">d{item?.menteeId}</p>
											</div>
									  ))
									: null}

								{/* <div className="modal-list">
									<div className="name-box">
										<i className="icon-checked">
											<CheckedIcon />
										</i>
										<p className="name">김길동</p>
									</div>
									<p className="number">d142310</p>
								</div>
								<div className="modal-list">
									<div className="name-box">
										<i className="icon-modal-plus">
											<ModalplusIcon />
										</i>
										<p className="name">박길동</p>
									</div>
									<p className="number">d142310</p>
								</div> */}
							</div>
						</div>
						<div className="modal-bg"></div>
					</div>

					<div className="btn-wrap">
						<button onClick={activity}>닫기</button>
						<button onClick={DoAdd} className="yellow-btn">
							등록
						</button>
					</div>
				</div>
			</section>
		</Wrapper>
	);
}
